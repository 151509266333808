import { useCallback, useEffect, useState } from 'react'

import { Save as SaveIcon, Delete as DeleteIcon } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Link, OutlinedInput } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridToolbarContainer,
  GridValidRowModel,
  ptBR,
  useGridApiContext,
} from '@mui/x-data-grid'
import { useMutation } from '@tanstack/react-query'
import InputCurrency from 'components/InputCurrency'
import InputPassword from 'components/InputPassword'
import LoadingFullPage from 'components/LoadingFullPage'
import Select from 'components/Select'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'
import { convertArrayOfObjectInSingleObjectToGridRowMode } from 'utils/convertArrayOfObjectInSingleObjectToGridRowMode'

type OnSave = (globalParameter: GlobalParametersApiRequest) => Promise<void>

type AddProps = {
  globalParameter?: GlobalParameter
  onSave?: OnSave
  isEditMode?: boolean
}

const EditField = ({
  id,
  value,
  field,
  error,
  onChange,
}: GridRenderEditCellParams) => {
  const apiRef = useGridApiContext()

  const handleValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newValue,
      })
    },
    [apiRef, field, id],
  )
  return (
    <OutlinedInput
      sx={{
        '& .MuiInputBase-input': {
          paddingTop: '0 !important',
          paddingLeft: '10px !important',
        },
        '& fieldset': error
          ? { borderRadius: '0 !important' }
          : { border: 'none' },
      }}
      error={error}
      autoFocus
      fullWidth
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event)
        handleValueChange(event)
      }}
    />
  )
}

export default function Add({
  globalParameter,
  onSave,
  isEditMode = false,
}: AddProps) {
  const navigate = useNavigate()

  const { openSuccessToast, openErrorToast } = useGlobalState()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
  } = useForm<
    GlobalParametersApiRequest & {
      installmentsForm: { installment: string; value: string }[]
    }
  >()

  const [formUpdated, setFormUpdated] = useState<boolean>(false)
  const [installmentsBudget, setInstallmentsBudget] = useState<Installment[]>(
    [],
  )

  useEffect(() => {
    if (isEditMode && !!globalParameter && !formUpdated) {
      reset({
        default_email: globalParameter.default_email,
        whatsapp_number: globalParameter.whatsapp_number,
        email_sending_smtp: globalParameter.email_sending_smtp,
        email_for_shots: globalParameter.email_for_shots,
        password_email_for_shots: globalParameter.password_email_for_shots,
        variable_budget: globalParameter.variable_budget,
        in_cash_budget: globalParameter.in_cash_budget,
        installments_budget: globalParameter.installments_budget,
        school_margin_budget: globalParameter.school_margin_budget,
        faculty_margin_budget: globalParameter.faculty_margin_budget,
        instagram_token: globalParameter.instagram_token,
        proposal_number: globalParameter.proposal_number,
      })

      setValue('pix_gateway_default', globalParameter.pix_gateway_default)
      setInstallmentsBudget(globalParameter.installments_budget)

      setFormUpdated(true)
    }
  }, [isEditMode, globalParameter, formUpdated, reset, setValue])

  const inputProps = useCallback(
    (fieldName: keyof GlobalParametersApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewGlobalParameter = useMutation({
    mutationFn: async (globalParameter: GlobalParametersApiRequest) => {
      await ApiService.GlobalParameters.create({
        ...globalParameter,
        installments_budget: installmentsBudget,
      })
    },
    onSuccess: (_data) => {
      openSuccessToast({
        message: `Parâmetro global foi adicionado(a) com sucesso!`,
      })

      navigate(ROUTES.GLOBAL_PARAMETERS.EDIT('1'))
    },
    onError: (_error) => {
      openErrorToast({
        message: `Erro ao adicionar o parâmetro global!`,
      })
    },
  })

  const AddToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => {
            setInstallmentsBudget((old) => [
              ...old,
              {
                id: Number((Math.random() * 999).toFixed()),
                installment: undefined,
                value: '',
                hasEdit: true,
              },
            ])
          }}
          style={{
            marginLeft: 'auto',
            display: 'block',
            marginTop: '1em',
          }}
        >
          Adicionar Parcelamento
        </Button>
      </GridToolbarContainer>
    )
  }

  const handleDeleteClick = useCallback(
    (id: GridRowId) => () => {
      setInstallmentsBudget(installmentsBudget.filter((row) => row.id !== id))
    },
    [installmentsBudget],
  )

  const processRowUpdate = useCallback(
    (newRow: GridRowModel) => {
      const id = newRow.id
      const changes = newRow
      const updatedRow = { ...changes, hasEdit: false } as Installment
      const updatedInstallmentItems = installmentsBudget.map(
        (oldInstallmentItem) =>
          oldInstallmentItem.id === id ? updatedRow : { ...oldInstallmentItem },
      )
      setInstallmentsBudget(updatedInstallmentItems)
      return updatedRow
    },
    [installmentsBudget],
  )

  const handleStartEdit = useCallback((id: GridRowId) => {
    setInstallmentsBudget((old) =>
      old.map((oldInstallmentItem) =>
        oldInstallmentItem.id === id
          ? {
              ...oldInstallmentItem,
              hasEdit: true,
            }
          : { ...oldInstallmentItem },
      ),
    )
  }, [])

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setInstallmentsBudget((old) =>
        old.map((oldInstallmentItem) =>
          oldInstallmentItem.id === id
            ? {
                ...oldInstallmentItem,
                hasEdit: false,
              }
            : { ...oldInstallmentItem },
        ),
      )
    },
    [],
  )

  if (isEditMode && !!globalParameter && formUpdated === false) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="hover"
              color="inherit"
              href={ROUTES.GLOBAL_PARAMETERS.EDIT('1')}
            >
              Parâmetro Global
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} Parâmetro Global
              </Typography>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit(
                (globalParameter: GlobalParametersApiRequest) => {
                  delete globalParameter.installmentsForm
                  if (isEditMode) {
                    onSave?.({
                      ...globalParameter,
                      installments_budget: installmentsBudget,
                    })
                    return
                  }

                  addNewGlobalParameter.mutate(globalParameter)
                },
              )}
            >
              <Grid container spacing={3} xs={12}>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={3} xs={12}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography sx={{ m: 1 }} variant="h5">
                              Dados gerais
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            error={!!errors.default_email?.message}
                            fullWidth
                            helperText={errors.default_email?.message}
                            label="Número do WhatsApp"
                            margin="normal"
                            variant="outlined"
                            {...inputProps('whatsapp_number')}
                            {...register('whatsapp_number')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={!!errors.instagram_token?.message}
                            fullWidth
                            helperText={errors.instagram_token?.message}
                            label="Token do instagram"
                            margin="normal"
                            variant="outlined"
                            {...inputProps('instagram_token')}
                            {...register('instagram_token')}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={3} xs={12}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography sx={{ m: 1 }} variant="h5">
                              E-mails e Disparos
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            error={!!errors.default_email?.message}
                            fullWidth
                            helperText={errors.default_email?.message}
                            label="Email padrão"
                            margin="normal"
                            type="email"
                            variant="outlined"
                            {...inputProps('default_email')}
                            {...register('default_email')}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            error={!!errors.email_sending_smtp?.message}
                            fullWidth
                            helperText={errors.email_sending_smtp?.message}
                            label="Email de envio smtp"
                            margin="normal"
                            type="email"
                            variant="outlined"
                            {...inputProps('email_sending_smtp')}
                            {...register('email_sending_smtp')}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            error={!!errors.email_for_shots?.message}
                            fullWidth
                            helperText={errors.email_for_shots?.message}
                            label="Email de disparo"
                            margin="normal"
                            type="email"
                            variant="outlined"
                            {...inputProps('email_for_shots')}
                            {...register('email_for_shots')}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <InputPassword
                            label="Senha do Email de disparo"
                            {...register('password_email_for_shots')}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={3} xs={12}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography sx={{ m: 1 }} variant="h5">
                              Orçamento
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            name="variable_budget"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <InputCurrency
                                  fullWidth
                                  label="Variável"
                                  id="variable_budget"
                                  onChange={onChange}
                                  value={value}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            name="in_cash_budget"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <InputCurrency
                                  fullWidth
                                  label="À Vista"
                                  id="in_cash_budget"
                                  onChange={onChange}
                                  value={value}
                                  {...inputProps('in_cash_budget')}
                                />
                              )
                            }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Controller
                            name="school_margin_budget"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <InputCurrency
                                  fullWidth
                                  label="Margem Escola (%)"
                                  id="school_margin_budget"
                                  onChange={onChange}
                                  value={value}
                                  {...inputProps('school_margin_budget')}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            name="faculty_margin_budget"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <InputCurrency
                                  fullWidth
                                  label="Margem Faculdade (%)"
                                  id="faculty_margin_budget"
                                  onChange={onChange}
                                  {...inputProps('faculty_margin_budget')}
                                  value={value}
                                />
                              )
                            }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Controller
                            name="pix_gateway_default"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  label="Gateway PIX padrão"
                                  id="pix_gateway_default"
                                  onChange={onChange}
                                  value={value}
                                  options={[{ label: 'Inter', value: 4 }]}
                                  autoFill={isEditMode}
                                  error={!!errors.pix_gateway_default?.message}
                                  helperText={
                                    errors.pix_gateway_default?.message
                                  }
                                />
                              )
                            }}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <TextField
                            error={!!errors.proposal_number?.message}
                            fullWidth
                            type="number"
                            helperText={errors.proposal_number?.message}
                            label="Número da proposta"
                            margin="normal"
                            variant="outlined"
                            {...inputProps('proposal_number')}
                            {...register('proposal_number')}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography sx={{ m: 1 }} variant="h6">
                              Parcelamento
                            </Typography>
                          </Box>
                          <DataGrid
                            sx={{
                              border: 0,
                              '& .MuiDataGrid-cell': {
                                paddingTop: '15px !important',
                                paddingBottom: '15px !important',
                              },
                              '& .custom-erro-input': {
                                outline: 'none !important',
                              },
                              '& .MuiDataGrid-cell--editing': {
                                padding: '0 10px !important',
                              },
                            }}
                            rowModesModel={convertArrayOfObjectInSingleObjectToGridRowMode(
                              {
                                data: installmentsBudget as {
                                  id: number
                                  hasEdit: boolean
                                }[],
                                field: 'installment',
                              },
                            )}
                            onRowEditStart={(_, event) => {
                              event.defaultMuiPrevented = true
                            }}
                            onRowClick={(params) => handleStartEdit(params.id)}
                            disableIgnoreModificationsIfProcessingProps
                            rows={installmentsBudget as GridValidRowModel[]}
                            experimentalFeatures={{ newEditingApi: true }}
                            processRowUpdate={processRowUpdate}
                            columns={[
                              {
                                field: 'installment',
                                headerName: 'Nº de Parcela',
                                flex: 1,
                                editable: true,
                                cellClassName: (
                                  params: GridCellParams<{ id: number }>,
                                ) => {
                                  const hasErro =
                                    !!errors?.installmentsForm?.[
                                      params.id as number
                                    ]?.installment

                                  return hasErro ? 'custom-erro-input' : ''
                                },
                                renderCell: (
                                  props: GridRenderEditCellParams,
                                ) => {
                                  const fieldName =
                                    `installmentsForm.${props.id}.installment` as keyof GlobalParametersApiRequest

                                  return (
                                    <Controller
                                      name={fieldName}
                                      control={control}
                                      rules={{ required: true }}
                                      defaultValue={installmentsBudget
                                        .find((item) => item.id === props.id)
                                        ?.installment?.toString()}
                                      render={({ field: { value } }) => {
                                        return (
                                          <EditField
                                            {...props}
                                            value={value}
                                            error={
                                              !!errors?.installmentsForm?.[
                                                props.id as number
                                              ]?.installment
                                            }
                                          />
                                        )
                                      }}
                                    />
                                  )
                                },
                                renderEditCell: (props) => {
                                  const fieldName =
                                    `installmentsForm.${props.id}.installment` as keyof GlobalParametersApiRequest

                                  return (
                                    <Controller
                                      name={fieldName}
                                      control={control}
                                      rules={{ required: true }}
                                      render={({
                                        field: { onChange, value },
                                      }) => {
                                        return (
                                          <EditField
                                            {...props}
                                            onChange={onChange}
                                            value={value}
                                            error={
                                              !!errors?.installmentsForm?.[
                                                props.id as number
                                              ]?.installment
                                            }
                                          />
                                        )
                                      }}
                                    />
                                  )
                                },
                              },
                              {
                                field: 'value',
                                headerName: 'Fator multiplicador',
                                flex: 1,
                                editable: true,
                                type: 'string',
                                cellClassName: (
                                  params: GridCellParams<{ id: number }>,
                                ) => {
                                  const hasErro =
                                    !!errors?.installmentsForm?.[
                                      params.id as number
                                    ]?.value

                                  return hasErro ? 'custom-erro-input' : ''
                                },
                                renderCell: (
                                  props: GridRenderEditCellParams,
                                ) => {
                                  const fieldName =
                                    `installmentsForm.${props.id}.value` as keyof GlobalParametersApiRequest

                                  return (
                                    <Controller
                                      name={fieldName}
                                      control={control}
                                      rules={{ required: true }}
                                      defaultValue={installmentsBudget
                                        .find((item) => item.id === props.id)
                                        ?.value?.toString()}
                                      render={({
                                        field: { value, onChange },
                                      }) => {
                                        return (
                                          <EditField
                                            {...props}
                                            value={value}
                                            error={
                                              !!errors?.installmentsForm?.[
                                                props.id as number
                                              ]?.value
                                            }
                                          />
                                        )
                                      }}
                                    />
                                  )
                                },
                                renderEditCell: (props) => {
                                  const fieldName =
                                    `installmentsForm.${props.id}.value` as keyof GlobalParametersApiRequest

                                  return (
                                    <Controller
                                      name={fieldName}
                                      control={control}
                                      rules={{ required: true }}
                                      render={({
                                        field: { onChange, value },
                                      }) => {
                                        return (
                                          <EditField
                                            {...props}
                                            onChange={onChange}
                                            value={value}
                                            error={
                                              !!errors?.installmentsForm?.[
                                                props.id as number
                                              ]?.value
                                            }
                                          />
                                        )
                                      }}
                                    />
                                  )
                                },
                              },
                              {
                                field: 'actions',
                                type: 'actions',
                                headerName: 'Ações',
                                width: 100,
                                cellClassName: 'actions',
                                renderCell: ({ id }) => {
                                  return (
                                    <>
                                      <GridActionsCellItem
                                        icon={<SaveIcon />}
                                        label="Save"
                                        onClick={handleSaveClick(id)}
                                      />
                                      <GridActionsCellItem
                                        key={`cancel-${id}`}
                                        icon={<DeleteIcon />}
                                        label="Delete"
                                        onClick={handleDeleteClick(id)}
                                        color="inherit"
                                      />
                                    </>
                                  )
                                },
                              },
                            ]}
                            editMode="row"
                            autoHeight
                            localeText={
                              ptBR.components.MuiDataGrid.defaultProps
                                .localeText
                            }
                            components={{ Footer: () => <AddToolbar /> }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Box
                style={{
                  display: 'block',
                  marginTop: '1em',
                  width: 'calc(100% - 24px)',
                }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={{
                    marginLeft: 'auto',
                    display: 'block',
                  }}
                >
                  {isEditMode ? 'Salvar' : 'Adicionar'}
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  )
}
