import { useCallback, useEffect, useMemo, useState } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import Mail from '@mui/icons-material/Mail'
import PictureAsPdf from '@mui/icons-material/PictureAsPdf'
import WhatsApp from '@mui/icons-material/WhatsApp'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import type {
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  GridFilterItem,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid'
import ConfirmationModal from 'components/ConfirmationModal'
import DataTable from 'components/DataTable'
import LoadingFullPage from 'components/LoadingFullPage'
import Select from 'components/Select'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { useQueryParams } from 'hooks/useQueryParams'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'

const handleGetTranslatedStatus: Record<StatusBudget, string> = {
  awaiting_approval: 'Aguardando Aprovação',
  approved: 'Aprovado',
  reproved: 'Reprovado',
}

export default function Budgets() {
  const navigate = useNavigate()
  const query = useQueryParams()

  const [messageModal, setMessageModal] = useState(false)
  const [mailModal, setMailModal] = useState(false)
  const [statusModal, setStatusModal] = useState(false)

  const [updatestatus, setUpdateStatus] = useState<string>('false')
  const [status, setStatus] = useState<string>()

  const [currentRow, setCurrentRow] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const { openSuccessToast, openInfoToast, openErrorToast } = useGlobalState()

  const [filterOptions, setFilterOptions] = useState<GridFilterItem[]>([])
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        columnField: '',
        id: 0,
        operatorValue: '',
        value: '',
      },
    ],
    quickFilterValues: [],
  })
  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const onConfirmMessage = useCallback(async () => {
    setIsSending(true)
    await ApiService.Budgets.sendMessageMail({
      id: Number(currentRow),
      data: {
        sendTo: 'message',
      },
    })
      .then((response) => {
        openSuccessToast({ message: 'A mensagem foi enviada' })
        setIsSending(false)
        setMessageModal(false)
      })
      .catch((error) => {
        const message = error?.message || 'Erro ao enviar mensagem.'
        openErrorToast({ message })
        setIsSending(false)
        setMessageModal(false)
      })
  }, [currentRow, openErrorToast, openSuccessToast])

  const onConfirmMail = useCallback(async () => {
    setIsSending(true)
    await ApiService.Budgets.sendMessageMail({
      id: Number(currentRow),
      data: {
        sendTo: 'mail',
      },
    })
      .then((response) => {
        openSuccessToast({ message: 'O email foi encaminhado' })
        setIsSending(false)
        setMailModal(false)
      })
      .catch((error) => {
        const message = error?.message || 'Erro ao enviar email.'
        openErrorToast({ message })
        setIsSending(false)
        setMailModal(false)
      })
  }, [currentRow, openErrorToast, openSuccessToast])

  const onConfirmStatus = useCallback(async () => {
    const ids = query
      .get('ids')
      ?.split(',')
      .map((item) => Number(item))

    if (!status || !ids?.length) {
      return
    }

    setIsSending(true)
    await ApiService.Budgets.updateManyStatus({
      budget_ids: ids,
      new_status: status,
    })
      .then((response) => {
        openSuccessToast({ message: 'O status foi alterado' })
        setIsSending(false)
        setStatusModal(false)
      })
      .catch((error) => {
        const message = error?.message || 'Erro ao alterar status.'
        openErrorToast({ message })
        setIsSending(false)
        setStatusModal(false)
      })
    const currentUrl = window.location.href
    let baseUrl = '/commercial/budgets'
    if (currentUrl.includes('budgets/')) {
      baseUrl = '/commercial/budgets/'
    }
    navigate(baseUrl)
    setStatus(undefined)
  }, [navigate, openErrorToast, openSuccessToast, query, status])

  const downloadProposal = useCallback((id: number) => {
    if (id) {
      ApiService.Budgets.downloadProposal({ id }).then((pdf) => {
        const blob = new Blob([pdf.data], {
          type: 'application/pdf',
        })
        const url = window.URL.createObjectURL(blob)
        window.open(url, '_blank')
      })
    }
  }, [])

  useEffect(() => {
    const updateStatus = query.get('update_status')

    setUpdateStatus(updateStatus || 'false')

    const status = query.get('status')
    if (!status) return

    setFilterModel({
      items: [
        {
          columnField: 'status',
          value: status,
        },
      ],

      quickFilterValues: [],
    })
    setFilterOptions([
      {
        columnField: 'status',
        value: status,
      },
    ])
  }, [query])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'commercialUnit.fantasy_name',
        headerName: 'Unidade Comercial',
        flex: 110,
        relation: {
          name: 'budget.commercialUnit',
          alias: 'commercialUnit',
        },
        valueGetter: (
          params: GridValueGetterParams<Reservation['commercialUnit']>,
        ) => params.row?.commercialUnit?.fantasy_name,
      },
      {
        field: 'institution.fantasy_name',
        headerName: 'Instituição',
        flex: 300,
        relation: {
          name: 'budget.institution',
          alias: 'institution',
        },
        valueGetter: (
          params: GridValueGetterParams<Reservation['institution']>,
        ) => params.row?.institution?.fantasy_name,
      },
      { field: 'name', headerName: 'Nome da atividade', width: 300 },
      {
        field: 'proposal_code',
        headerName: 'Cod. Proposta',
        width: 100,
      },
      {
        field: 'proposal_price',
        headerName: 'Preço',
        width: 100,
        valueGetter: (
          params: GridValueGetterParams<Reservation['institution']>,
        ) => {
          const budget = params.row
          if (!budget.proposal_price || budget.proposal_price === '0,00') {
            const valueInstallment = budget.installments?.[0]?.value
            return valueInstallment
          }
          return budget.proposal_price
        },
      },
      {
        field: 'number_of_people',
        headerName: 'Nº mín. de pessoas',
        width: 40,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 180,
        type: 'singleSelect',
        valueOptions: [
          { value: 'awaiting_approval', label: 'Aguardando Aprovação' },
          { value: 'approved', label: 'Aprovado' },
          { value: 'reproved', label: 'Reprovado' },
        ],
        valueFormatter: ({ value }) =>
          `${handleGetTranslatedStatus[value as StatusBudget]}`,
      },
      {
        field: 'created_at',
        headerName: 'Criado em',
        width: 115,
      },
      {
        field: 'actions',
        headerName: 'Ações',
        width: 120,
        sortable: false,
        exclude: true,
        renderCell: (params: GridRenderCellParams<Budget>) => {
          return (
            <>
              <PictureAsPdf
                fontSize="small"
                style={{ margin: '5px' }}
                onClick={() => {
                  if (!params.row.proposal_code) {
                    openInfoToast({
                      message: 'Proposta ainda não foi preenchida',
                    })
                    return
                  }
                  downloadProposal(params.row.id)
                }}
              />
              <WhatsApp
                fontSize="small"
                onClick={() => {
                  setCurrentRow(params.row.id)
                  setMessageModal(true)
                }}
              />
              <Mail
                fontSize="small"
                style={{ margin: '5px' }}
                onClick={() => {
                  setCurrentRow(params.row.id)
                  setMailModal(true)
                }}
              />
              <EditIcon
                sx={{
                  marginRight: '10px',
                }}
                onClick={() => {
                  navigate(ROUTES.BUDGETS.EDIT(params.row.id))
                }}
              />
            </>
          )
        },
      },
    ],
    [downloadProposal, navigate, openInfoToast],
  )
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const filter = filterModel.items
      if (!filter?.[0]?.value === null || !filter?.[0]?.value === undefined)
        return
      setFilterOptions(filter)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [filterModel.items])

  const handleFilter = useCallback(async (filter: GridFilterModel) => {
    if (!filter?.items?.length) return
    setFilterModel(filter)
  }, [])

  if (isSending) {
    return <LoadingFullPage />
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Typography sx={{ m: 1 }} variant="h4">
                Orçamentos
              </Typography>

              <Box sx={{ m: 1 }}>
                {updatestatus === 'true' && (
                  <Button
                    color="info"
                    variant="contained"
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      setStatusModal(true)
                    }}
                  >
                    Atualizar Status
                  </Button>
                )}

                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.BUDGETS.CREATE)
                  }}
                >
                  Adicionar
                </Button>
              </Box>
            </Box>
          </Box>

          <Card sx={{ mt: 3 }}>
            <CardContent>
              <DataTable
                columns={columns}
                entity="Budget"
                source="budget"
                onSortModelChange={(newSortModel) => {
                  setSortModel(newSortModel)
                }}
                sortParameters={sortModel}
                filterModel={filterModel}
                onFilterModelChange={handleFilter}
                likeParameters={filterOptions}
                enableCheckBox
                onSelectRow={(data) => {
                  const currentUrl = window.location.href
                  let baseUrl = '/commercial/budgets'
                  if (currentUrl.includes('budgets/')) {
                    baseUrl = '/commercial/budgets/'
                  }

                  if (data.length) {
                    navigate(
                      `${baseUrl}?update_status=true&ids=${data.join(',')}`,
                    )
                  }
                  if (!data.length) {
                    navigate(baseUrl)
                  }
                }}
              />
            </CardContent>
          </Card>
        </Container>
      </Box>
      <ConfirmationModal
        title={`Confirmar o envio pelo whatsapp para todos os professores?`}
        opened={messageModal}
        onClose={() => setMessageModal(false)}
        onConfim={onConfirmMessage}
        onCancel={() => setMessageModal(false)}
      ></ConfirmationModal>
      <ConfirmationModal
        title={`Confirmar o envio pelo email para todos os professores?`}
        opened={mailModal}
        onClose={() => setMailModal(false)}
        onConfim={onConfirmMail}
        onCancel={() => setMailModal(false)}
      ></ConfirmationModal>
      <ConfirmationModal
        title={`Selecionar o status para os orçamentos selecionados`}
        opened={statusModal}
        onClose={() => {
          setStatus(undefined)
          setStatusModal(false)
        }}
        onConfim={onConfirmStatus}
        onCancel={() => {
          setStatus(undefined)
          setStatusModal(false)
        }}
      >
        <Grid container spacing={3} xs={12}>
          <Grid item xs={12}>
            <Select
              label="Status"
              id="status"
              name="status"
              value={status}
              onChange={(e) => setStatus(String(e.target.value))}
              options={[
                {
                  value: 'awaiting_approval',
                  label: 'Aguardando Aprovação',
                },
                {
                  value: 'approved',
                  label: 'Aprovado',
                },
                {
                  value: 'reproved',
                  label: 'Reprovado',
                },
              ]}
            />
          </Grid>
        </Grid>
      </ConfirmationModal>
    </>
  )
}
