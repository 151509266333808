import { useCallback, useEffect, useMemo, useState } from 'react'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { useQuery } from '@tanstack/react-query'
import ConfirmationModal from 'components/ConfirmationModal'
import DatePicker from 'components/DatePicker'
import InputCurrency from 'components/InputCurrency'
import Select, { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { Controller, useFormContext, UseFormSetValue } from 'react-hook-form'
import { ApiService } from 'services/api'
import { generateId } from 'utils/random'

import { formatToNumber } from '../add'
import { ACTIVITY_STATUS_OPTION, ACTIVITY_TYPES_OPTION } from '../types'
import { createDefaultDescriptionProposalData } from './create-default-description'

const INSTALLMENT_OPTIONS = Array.from(new Array(12).fill(0)).map(
  (_, index) => ({
    label: 1 + index === 1 ? 'A vista' : `${1 + index}x`,
    value: 1 + index,
  }),
)

const PAYMENT_METHOD = [
  {
    label: 'Ambos',
    value: 'both',
  },
  {
    label: 'Cartão de crédito',
    value: 'credit_card',
  },
  {
    label: 'Pix',
    value: 'pix',
  },
]

type ActivityFormProps = {
  isEditMode: boolean
  inputProps: (fieldName: keyof ActivityApiRequest) => {
    InputLabelProps: {
      shrink: boolean
    }
  }
  setValue: UseFormSetValue<ActivityApiRequest>
}

const ACTIVITY_TYPES_SCHOOL = ['school', 'nr graduation']

export const ActivityForm = ({
  isEditMode = false,
  inputProps,
  setValue: setValueDescription,
}: ActivityFormProps) => {
  const { control, watch, register, setValue, formState } =
    useFormContext<ActivityApiRequest>()
  const budget_id = watch('budget_id')
  const activityIsTypeSchool = ACTIVITY_TYPES_SCHOOL.includes(watch('type'))
  const { data: paymentGateways } = useQuery<PaymentGateway[]>(
    QUERY_KEYS.PAYMENT_GATEWAYS.LIST,
    () => ApiService.PaymentGateways.getAll(),
  )

  const paymentGatewayOptions = useMemo(
    () =>
      paymentGateways?.map((paymentGateway) => ({
        label: paymentGateway.account_name,
        value: paymentGateway.id,
      })) || [],
    [paymentGateways],
  )

  const { data: budgets } = useQuery<BudgetApiResponse[]>(
    QUERY_KEYS.BUDGETS.NAME_AND_IDS_CURRENT_YEAR,
    () =>
      ApiService.Budgets.getAllNamesAndIdsCurrentYear({
        budget_id,
      }),
  )

  const [budgetOptions, setBudgetOptions] = useState<SelectProps['options']>([])
  const [modalUpdateDescription, setModalUpdateDescription] = useState<{
    isActive: boolean
    selectedBudget?: BudgetApiResponse
  }>({
    isActive: false,
    selectedBudget: undefined,
  })

  const { data: institutions, isLoading: isLoadingInstituitions } = useQuery<
    Institution[]
  >(QUERY_KEYS.INSTITUTIONS.NAMES_AND_IDS, () =>
    ApiService.Institutions.getAll({
      fields: ['fantasy_name', 'id'],
      relations: [],
    }),
  )

  const institutionOptions = useMemo(
    () =>
      institutions?.map((institution) => ({
        label: institution.fantasy_name,
        value: institution.id,
      })) || [],
    [institutions],
  )

  const { data: collaborators, isLoading: isLoadingCollaborators } = useQuery<
    SelectProps['options']
  >(QUERY_KEYS.COLLABORATORS.NAME_AND_IDS_ONLY_EMPLOYEE, () =>
    ApiService.Collaborators.getAllNamesAndIdsOnlyEmployeeOptions(),
  )

  const collaboratorOptions = useMemo(
    () => collaborators || [],
    [collaborators],
  )

  const institutionId = watch('institution_id')

  const { data: campi } = useQuery<Campus[]>(
    QUERY_KEYS.CAMPUS.BY_INSTITUTION(institutionId),
    () => {
      return institutionId
        ? ApiService.Campus.getAllByInstitution(institutionId)
        : []
    },
  )

  const campusOptions = useMemo(
    () =>
      campi?.map((campus) => ({
        label: campus.name,
        value: campus.id,
      })) || [],
    [campi],
  )

  useEffect(() => {
    if (!isEditMode) {
      const randomActivityId = generateId()
      setValue('code', randomActivityId)
    }
  }, [isEditMode, setValue])

  const onConfirmUpdateDescription = useCallback(() => {
    if (modalUpdateDescription.selectedBudget) {
      setValueDescription(
        'description',
        createDefaultDescriptionProposalData(
          modalUpdateDescription.selectedBudget,
        ),
      )
    }
    setModalUpdateDescription({
      isActive: false,
      selectedBudget: undefined,
    })
  }, [modalUpdateDescription.selectedBudget, setValueDescription])

  const handleGetValueBudget = useCallback(
    async (budget_id: number, type: 'onLoad' | 'onClick' = 'onLoad') => {
      const selectedBudget = budgets?.find((budget) => budget.id === budget_id)

      if (selectedBudget) {
        if (!isEditMode) {
          setValueDescription(
            'description',
            createDefaultDescriptionProposalData({ ...selectedBudget }),
          )
        }

        if (isEditMode && type === 'onClick') {
          setModalUpdateDescription({
            isActive: true,
            selectedBudget,
          })
        }

        const shippingBudgetItem =
          await ApiService.BudgetItems.getItemByBudgetId({
            budget_id: selectedBudget?.id,
            item_budget_id: 1,
          })
        setValue(
          'commercial_unit_id',
          parseInt(selectedBudget.commercial_unit_id, 10),
        )
        if (!isEditMode) {
          setValue(
            'payment_gateway_id',
            Number(selectedBudget.payment_gateway_id),
          )
        }
        setValue('viability', Number(selectedBudget.number_of_people))

        if (!isEditMode) {
          setValue('name', selectedBudget.name)
          setValue('type', selectedBudget.type_activity as ActivityType)
        }
        if (shippingBudgetItem?.supplier?.id !== 45) {
          setValue(
            'shipping_company',
            shippingBudgetItem?.supplier?.fantasy_name ||
              shippingBudgetItem?.supplier?.name ||
              '',
          )
          setValue(
            'value_shipping_company',
            shippingBudgetItem?.unit_value || '',
          )
        }
        if (selectedBudget?.installments?.length) {
          setValue('installments', [
            ...selectedBudget.installments.map((installment) => ({
              installment: Number(installment.installment),
              value_per_installment: formatToNumber(installment.value),
            })),
            {
              value_per_installment: formatToNumber(selectedBudget?.cash_value),
              installment: 1,
            },
          ])
        }
      }
    },
    [budgets, isEditMode, setValue, setValueDescription],
  )

  const handleClearValueBudget = useCallback(() => {
    setValue('commercial_unit_id', 0)
    setValue('payment_gateway_id', 0)
    setValue('viability', 0)
    setValue('name', '')
    setValue('type', '' as ActivityType)
    setValue('shipping_company', '')
    setValue('value_shipping_company', '')
  }, [setValue])

  useEffect(() => {
    if (budget_id && Array.isArray(budgets)) {
      handleGetValueBudget(budget_id)
    }
  }, [budget_id, budgets, handleGetValueBudget, setValue])

  const handleLoadBudgetOptions = useCallback(
    (institution_id: string | number) => {
      const findBudgetsByInstitution = budgets?.filter(
        (budget) => Number(budget.institution_id) === Number(institution_id),
      )

      let filterBudgets = findBudgetsByInstitution
      if (!filterBudgets?.length) {
        filterBudgets = budgets
      }
      setBudgetOptions(
        (filterBudgets || [])
          ?.filter((item) => item.status === 'approved')
          .map((budget) => ({
            label: budget.name,
            value: budget.id,
          })),
      )
    },
    [budgets],
  )

  useEffect(() => {
    if (!budgets || !Array.isArray(budgets)) return
    handleLoadBudgetOptions(institutionId)
  }, [budgets, handleLoadBudgetOptions, institutionId])

  return (
    <Grid container spacing={3} rowSpacing={1} marginTop={1}>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Código Identificação atividade"
          margin="normal"
          type="text"
          variant="outlined"
          {...inputProps('code')}
          {...register('code')}
          disabled
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          name="institution_id"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                error={!!formState?.errors?.institution_id?.message}
                helperText={formState?.errors?.institution_id?.message}
                label="Instituição"
                id="institution_id"
                onChange={(event) => {
                  handleLoadBudgetOptions(event.target.value as string)
                  onChange(event)
                }}
                value={value ?? 0}
                disabled={isLoadingInstituitions}
                options={institutionOptions}
                autoFill={isEditMode}
              />
            )
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          name="campus_institution_id"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                error={!!formState?.errors?.campus_institution_id?.message}
                helperText={formState?.errors?.campus_institution_id?.message}
                label="Unidade"
                id="campus_institution_id"
                onChange={onChange}
                value={value ?? 0}
                disabled={campi && campi?.length <= 0}
                options={campusOptions}
                autoFill={isEditMode}
              />
            )
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          name="budget_id"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                error={!!formState?.errors?.budget_id?.message}
                helperText={formState?.errors?.budget_id?.message}
                label="Orçamento"
                id="budget_id"
                onChange={(event) => {
                  onChange(event)
                  if (event.target.value === '') {
                    handleClearValueBudget()
                    return
                  }
                  handleGetValueBudget(Number(event.target.value), 'onClick')
                }}
                value={value ?? 0}
                disabled={!watch('institution_id')}
                options={budgetOptions}
                autoFill={isEditMode}
              />
            )
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          error={!!formState?.errors?.name?.message}
          helperText={formState?.errors?.name?.message}
          fullWidth
          label="Nome da Atividade"
          margin="normal"
          type="text"
          variant="outlined"
          {...inputProps('name')}
          {...register('name')}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          error={!!formState?.errors?.course_name?.message}
          helperText={formState?.errors?.course_name?.message}
          fullWidth
          label="Nome do Curso"
          margin="normal"
          type="text"
          variant="outlined"
          {...inputProps('course_name')}
          {...register('course_name')}
        />
      </Grid>

      {!activityIsTypeSchool && (
        <Grid item xs={3}>
          <TextField
            error={!!formState?.errors?.certificate_name?.message}
            helperText={formState?.errors?.certificate_name?.message}
            fullWidth
            label="Nome do Certificado"
            margin="normal"
            type="text"
            variant="outlined"
            {...inputProps('certificate_name')}
            {...register('certificate_name')}
          />
        </Grid>
      )}

      {!activityIsTypeSchool && (
        <Grid item xs={1} style={{ margin: '16px 0 8px' }}>
          <TextField
            error={!!formState?.errors?.extracurricular_hours?.message}
            helperText={formState?.errors?.extracurricular_hours?.message}
            fullWidth
            label="Horas extracurriculares"
            defaultValue={0}
            type="number"
            variant="outlined"
            {...inputProps('extracurricular_hours')}
            {...register('extracurricular_hours')}
          />
        </Grid>
      )}

      <Grid item xs={4}>
        <Controller
          name="type"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                error={!!formState?.errors?.type?.message}
                helperText={formState?.errors?.type?.message}
                label="Tipo de Atividade"
                id="type"
                onChange={onChange}
                value={value ?? ''}
                options={ACTIVITY_TYPES_OPTION}
                autoFill={true}
                disabled
              />
            )
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          name="responsible_id"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                error={!!formState?.errors?.responsible_id?.message}
                helperText={formState?.errors?.responsible_id?.message}
                label="Responsável pela Atividade"
                id="responsible_id"
                onChange={onChange}
                value={value ?? 0}
                disabled={isLoadingCollaborators}
                options={collaboratorOptions}
                autoFill={isEditMode}
              />
            )
          }}
        />
      </Grid>

      <Grid item xs={2} style={{ margin: '16px 0 8px' }}>
        <Controller
          name="enrollment_limit"
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePicker
                error={!!formState?.errors?.enrollment_limit?.message}
                helperText={formState?.errors?.enrollment_limit?.message}
                fullWidth
                label="Limite Inscrição"
                id="enrollment_limit"
                {...inputProps('enrollment_limit')}
                onChange={onChange}
                value={value}
              />
            )
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name="accept_subscription"
          control={control}
          defaultValue={'1'}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                label="Aceita inscrição"
                id="accept_subscription"
                onChange={onChange}
                value={value}
                autoFill={isEditMode}
                {...inputProps('accept_subscription')}
                options={[
                  {
                    label: 'Sim',
                    value: '1',
                  },
                  {
                    label: 'Não',
                    value: '0',
                  },
                ]}
              />
            )
          }}
        />
      </Grid>

      <Grid item xs={4} alignItems="end" style={{ margin: '16px 0 8px' }}>
        <Controller
          name="start_travel_date"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePicker
                error={!!formState?.errors?.start_travel_date?.message}
                helperText={formState?.errors?.start_travel_date?.message}
                {...inputProps('start_travel_date')}
                label="Data da viagem - Início"
                id="start_travel_date"
                onChange={onChange}
                value={value}
                fullWidth
              />
            )
          }}
        />
      </Grid>

      <Grid item xs={4} justifyContent="end" style={{ margin: '16px 0 8px' }}>
        <Controller
          name="end_travel_date"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePicker
                error={!!formState?.errors?.end_travel_date?.message}
                helperText={formState?.errors?.end_travel_date?.message}
                {...inputProps('end_travel_date')}
                fullWidth
                label="Data da viagem - Fim"
                id="end_travel_date"
                onChange={onChange}
                value={value}
              />
            )
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <Controller
          name="price"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <InputCurrency
                error={!!formState?.errors?.price?.message}
                helperText={formState?.errors?.price?.message}
                fullWidth
                label="Preço"
                id="price"
                onChange={onChange}
                value={value}
              />
            )
          }}
        />
      </Grid>

      <Grid item xs={2} style={{ margin: '16px 0 8px' }}>
        <TextField
          error={!!formState?.errors?.viability?.message}
          helperText={formState?.errors?.viability?.message}
          fullWidth
          label="Viabilidade da atividade"
          type="number"
          variant="outlined"
          disabled
          {...inputProps('viability')}
          {...register('viability')}
        />
      </Grid>

      <Grid item xs={2}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Controller
              name="payment_method"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    error={!!formState?.errors?.payment_method?.message}
                    helperText={formState?.errors?.payment_method?.message}
                    label="Método de pagamentos"
                    id="payment_method"
                    onChange={onChange}
                    value={value ?? 'both'}
                    options={PAYMENT_METHOD}
                    autoFill
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={2}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Controller
              name="interest_free_installment"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    error={
                      !!formState?.errors?.interest_free_installment?.message
                    }
                    helperText={
                      formState?.errors?.interest_free_installment?.message
                    }
                    label="Parcelamento sem juros"
                    id="interest_free_installment"
                    onChange={onChange}
                    value={value ?? 0}
                    options={INSTALLMENT_OPTIONS}
                    autoFill
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Controller
          name="payment_gateway_id"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                error={!!formState?.errors?.payment_gateway_id?.message}
                helperText={formState?.errors?.payment_gateway_id?.message}
                label="Contas de Pagamento"
                id="payment_gateway_id"
                onChange={onChange}
                value={value ?? 0}
                options={paymentGatewayOptions}
                autoFill
              />
            )
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <TextField
          error={!!formState?.errors?.initial_stock?.message}
          helperText={formState?.errors?.initial_stock?.message}
          fullWidth
          label="Estoque inicial"
          margin="normal"
          type="number"
          variant="outlined"
          {...register('initial_stock')}
          {...inputProps('initial_stock')}
        />
      </Grid>

      {isEditMode && (
        <Grid item xs={3}>
          <Controller
            name="status"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  error={!!formState?.errors?.status?.message}
                  helperText={formState?.errors?.status?.message}
                  label="Status"
                  id="status"
                  onChange={onChange}
                  value={value ?? ''}
                  options={ACTIVITY_STATUS_OPTION}
                  autoFill={isEditMode}
                />
              )
            }}
          />
        </Grid>
      )}
      <ConfirmationModal
        title={`Atualizar a descrição com os dados da proposta?`}
        opened={modalUpdateDescription.isActive}
        onClose={() =>
          setModalUpdateDescription({
            isActive: false,
            selectedBudget: undefined,
          })
        }
        onConfim={onConfirmUpdateDescription}
        onCancel={() =>
          setModalUpdateDescription({
            isActive: false,
            selectedBudget: undefined,
          })
        }
      ></ConfirmationModal>
    </Grid>
  )
}
