export const programIncludes = `Transporte rodoviário executivo - ida/ volta;
Atividades de acordo com a programação do professor:
01 Guia acompanhante;
02 Monitores acompanhantes/ Turismo Estudantil;
02 Professores acompanhantes - cortesia;
Seguro individual de viagem.`

export const paymentMethod = `À vista: Pix ou cartão de crédito.
Parcelado: 03x sem juros no cartão.`

export const important = `Prazo limite da proposta: 03 dias.
Não atingindo o mínimo, a atividade poderá ser cancelada.
Todos os passageiros deverão portar documento de identificação oficial e original com foto.`

export const theAdvertisedPriceDoesNotInclude = `Refeições não mencionadas no programa;
Passeios opcionais ou extensões que não constem no programa;
Tudo o que não estiver mencionado no item "O programa inclui"`

export const notes = `Valores sujeitos à alteração sem prévio aviso;
Confirmação de reserva sujeita à disponibilidade de lugares;
Os valores acima referem-se às datas e locais de saída especificados.

ATENÇÃO: ao realizar uma compra pela internet, dê preferência aos cartões virtuais. Verifique se o navegador utilizado está atualizado e faça varreduras constantes através de sistemas antivírus. O site da Turismo Estudantil NÃO grava os dados do seu cartão de crédito.`
