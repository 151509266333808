import { forwardRef, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import ReactQuillComponent from 'react-quill'
import 'react-quill/dist/quill.snow.css'

type HtmlEditorProps = {
  onChange?: (value: string) => void
  defaultValue?: string
}

const ReactQuill = styled(ReactQuillComponent)`
  .ql-editor {
    font-size: 16px;
  }
`

const HtmlEditor = forwardRef(function HtmlEditor(
  props: HtmlEditorProps,
  _ref: React.ForwardedRef<unknown>,
) {
  const [editorState, setEditorState] = useState<string>(
    props.defaultValue || '',
  )

  useEffect(() => {
    if (props.defaultValue) {
      setEditorState(props.defaultValue)
    }
  }, [props.defaultValue])

  return (
    <ReactQuill
      theme="snow"
      value={editorState}
      onChange={(value) => {
        setEditorState(value)
        props.onChange?.(value)
      }}
      onKeyDown={(event) => {
        if (event.key === 'Tab') {
          event.preventDefault()
          event.stopPropagation()
        }
      }}
      formats={[
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'link',
        'video',
        'color',
        'background',
      ]}
      modules={{
        toolbar: {
          container: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'video'],
            ['clean'],
            [{ color: [] }, { background: [] }],
          ],
        },
      }}
    />
  )
})

export default HtmlEditor
