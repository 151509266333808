type IProposalData = {
  program_includes?: string
  payment_method?: string
  important?: string
  the_advertised_price_does_not_include?: string
  notes?: string
  number_of_people?: string
  proposal_price?: string
  installments?: { value: string }[]
}

const buildParagraphs = (text: string) =>
  text
    .split('\n')
    .map((item) => `<p>${item}</p>`)
    .join('')

export const createDefaultDescriptionProposalData = ({
  program_includes,
  payment_method,
  important,
  the_advertised_price_does_not_include,
  notes,
  number_of_people,
  proposal_price,
  installments,
}: IProposalData) => {
  let result = ``

  if (program_includes) {
    result += `<b>O programa inclui</b>`
    result += buildParagraphs(program_includes)
  }

  let price = proposal_price

  if (!proposal_price || proposal_price === '0,00') {
    const valueInstallment = installments?.[0]?.value
    price = valueInstallment
  }

  result += `<br><b>Valor por aluno: R$ ${price}</b>`
  result += buildParagraphs(
    `Grupo mínimo de ${number_of_people} alunos pagantes`,
  )

  if (payment_method) {
    result += `<br><b>Condições de pagamento</b>`
    result += buildParagraphs(payment_method)
  }
  if (important) {
    result += `<br><b>Importante</b>`
    result += buildParagraphs(important)
  }
  if (the_advertised_price_does_not_include) {
    result += `<br><b>O preço anunciado não inclui</b>`
    result += buildParagraphs(the_advertised_price_does_not_include)
  }
  if (notes) {
    result += `<br><b>Observações</b>`
    result += buildParagraphs(notes)
  }

  return result
}
