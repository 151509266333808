import { Diversity3 } from '@mui/icons-material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import QueryStats from '@mui/icons-material/QueryStats'
import { Avatar, Card, CardContent, Stack, SvgIcon } from '@mui/material'
import Box from '@mui/material/Box'
import CardActions from '@mui/material/CardActions'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { SxProps } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import LoadingFullPage from 'components/LoadingFullPage'
import { ENDPOINTS } from 'constants/endpoints'
import { QUERY_KEYS } from 'constants/keys'
import { INPUT_MASK } from 'constants/masks'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'
import { currencyMask } from 'utils/mask'
type CardWithStatisticsProps = {
  sx?: SxProps
  title?: string
  value?: string
  iconSettings: {
    sx?: SxProps
    icon: import('react').ReactNode
    handleClick?: () => void
  }
}

function CardWithStatistics({
  sx,
  title,
  value,
  iconSettings,
  ...other
}: CardWithStatisticsProps) {
  return (
    <Card sx={sx} {...other} style={{ minWidth: '400px' }}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              {title}
            </Typography>
            <Typography variant="h3">{value}</Typography>
          </Stack>
          <Stack spacing={1}>
            {iconSettings && (
              <Box sx={{ justifyContent: 'end' }}>
                <Avatar
                  sx={{
                    backgroundColor: 'error.main',
                    height: 56,
                    width: 56,
                    ...iconSettings?.sx,
                  }}
                >
                  <SvgIcon>{iconSettings.icon}</SvgIcon>
                </Avatar>
              </Box>
            )}
            {iconSettings?.handleClick && (
              <CardActions>
                <ArrowForwardIcon
                  style={{ cursor: 'pointer' }}
                  onClick={iconSettings?.handleClick}
                />
              </CardActions>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

const START_LAST_30_DAYS = moment()
  .subtract(30, 'days')
  .format(INPUT_MASK.DATE_EUA)
const END_LAST_30_DAYS = moment().format(INPUT_MASK.DATE_EUA)

function hasPermission(err: ApiError) {
  if (!err) return true
  if (
    err.status === 403 &&
    (err.message === 'Not authorized!' ||
      err.message.includes('não autorizado'))
  ) {
    return false
  }
  return true
}

const DEFAULT_OPTIONS_QUERY = {
  retry: false,
  refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
}

export default function Dashboard() {
  const { user } = useGlobalState()
  const navigate = useNavigate()
  const {
    data: activityChecklistPending,
    error: activityChecklistPendingError,
    isLoading: activityChecklistPendingLoading,
  } = useQuery(
    QUERY_KEYS.REPORTS.ACTIVITY_CHECKLIST,
    async ({ signal }) => {
      return ApiService.Reports.generateActivityChecklistStatus({ signal })
    },
    DEFAULT_OPTIONS_QUERY,
  )
  const {
    data: reservationPaid,
    error: reservationPaidError,
    isLoading: reservationPaidLoading,
  } = useQuery(
    QUERY_KEYS.REPORTS.RESERVATION_PAID,
    async ({ signal }) => {
      return ApiService.Reports.generateReservationPaid({ signal })
    },
    DEFAULT_OPTIONS_QUERY,
  )
  const {
    data: reservationAwaitingPayment,
    error: reservationAwaitingPaymentError,
    isLoading: reservationAwaitingPaymentLoading,
  } = useQuery(
    QUERY_KEYS.REPORTS.RESERVATION_AWAITING_PAYMENT,
    async ({ signal }) => {
      return ApiService.Reports.generateReservationAwaitingPayment({ signal })
    },
    DEFAULT_OPTIONS_QUERY,
  )
  const {
    data: budgetWaitingApproved,
    error: budgetWaitingApprovedError,
    isLoading: budgetWaitingApprovedLoading,
  } = useQuery(
    QUERY_KEYS.REPORTS.BUDGET_AWAITING_APPROVAL,
    async ({ signal }) => {
      return ApiService.Reports.generateBudgetAwaitingApproval({ signal })
    },
    DEFAULT_OPTIONS_QUERY,
  )
  const {
    data: confirmedActivity,
    error: confirmedActivityError,
    isLoading: confirmedActivityLoading,
  } = useQuery(
    QUERY_KEYS.REPORTS.ACTIVITY_CONFIRMED,
    async ({ signal }) => {
      return ApiService.Reports.generateActivityConfirmed({ signal })
    },
    DEFAULT_OPTIONS_QUERY,
  )
  const {
    data: openedActivity,
    error: openedActivityError,
    isLoading: openedActivityLoading,
  } = useQuery(
    QUERY_KEYS.REPORTS.ACTIVITY_OPENED,
    async ({ signal }) => {
      return ApiService.Reports.generateActivityOpened({ signal })
    },
    DEFAULT_OPTIONS_QUERY,
  )
  const {
    data: cancelledActivity,
    error: cancelledActivityError,
    isLoading: cancelledActivityLoading,
  } = useQuery(
    QUERY_KEYS.REPORTS.ACTIVITY_CANCELLED,
    async ({ signal }) => {
      return ApiService.Reports.generateActivityCancelled({
        signal,
        notSave: true,
      })
    },
    DEFAULT_OPTIONS_QUERY,
  )
  const {
    data: averageTicket,
    error: averageTicketError,
    isLoading: averageTicketLoading,
  } = useQuery(QUERY_KEYS.INDICATORS.VIEW, async () => {
    return ApiService.Indicators.get()
  })
  const {
    data: checkPermissionSchoolTrip,
    isLoading: checkPermissionSchoolTripLoading,
  } = useQuery(
    QUERY_KEYS.CHECK_PERMISSION.VERIFY(
      ENDPOINTS.REPORTS.SCHOOL_TRIP,
      user?.id,
      user?.role_id,
    ),
    async () => {
      return ApiService.CheckPermission.verify({
        method: 'POST',
        originalUrl: ENDPOINTS.REPORTS.SCHOOL_TRIP,
        role_id: user?.role_id as number,
        user_id: user?.id as number,
      })
    },
    DEFAULT_OPTIONS_QUERY,
  )
  const {
    data: checkPermissionFacultyTrip,
    isLoading: checkPermissionFacultyTripLoading,
  } = useQuery(
    QUERY_KEYS.CHECK_PERMISSION.VERIFY(
      ENDPOINTS.REPORTS.FACULTY_TRIP,
      user?.id,
      user?.role_id,
    ),
    async () => {
      return ApiService.CheckPermission.verify({
        method: 'POST',
        originalUrl: ENDPOINTS.REPORTS.FACULTY_TRIP,
        role_id: user?.role_id as number,
        user_id: user?.id as number,
      })
    },
    DEFAULT_OPTIONS_QUERY,
  )
  const {
    data: checkPermissionActivityControl,
    isLoading: checkPermissionActivityControlLoading,
  } = useQuery(
    QUERY_KEYS.CHECK_PERMISSION.VERIFY(
      ENDPOINTS.ACTIVITIES.GET_ALL_CALENDAR,
      user?.id,
      user?.role_id,
    ),
    async () => {
      return ApiService.CheckPermission.verify({
        method: 'GET',
        originalUrl: ENDPOINTS.ACTIVITIES.GET_ALL_CALENDAR,
        role_id: user?.role_id as number,
        user_id: user?.id as number,
      })
    },
    DEFAULT_OPTIONS_QUERY,
  )

  if (
    averageTicketLoading ||
    cancelledActivityLoading ||
    openedActivityLoading ||
    confirmedActivityLoading ||
    reservationPaidLoading ||
    reservationAwaitingPaymentLoading ||
    budgetWaitingApprovedLoading ||
    checkPermissionSchoolTripLoading ||
    checkPermissionFacultyTripLoading ||
    activityChecklistPendingLoading ||
    checkPermissionActivityControlLoading
  ) {
    return <LoadingFullPage />
  }
  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
          xs={12}
          direction={{ xs: 'column', md: 'row' }}
          sx={{ '& .MuiGrid-item': { paddingLeft: { xs: 0, md: '24px' } } }}
        >
          {hasPermission(reservationAwaitingPaymentError as ApiError) && (
            <Grid item xs={3}>
              <CardWithStatistics
                iconSettings={{
                  icon: <HourglassTopIcon />,
                  sx: {
                    backgroundColor: 'warning.light',
                  },
                  handleClick: () => {
                    navigate(
                      ROUTES.RESERVATIONS.LIST +
                        `?situation=waiting_payment&situation=realized`,
                    )
                  },
                }}
                value={currencyMask(
                  reservationAwaitingPayment?.totalWaitingPaymentValue || 0,
                  {
                    style: 'currency',
                    currency: 'BRL',
                  },
                )}
                title="Reservas aguardando pagamento"
              />
            </Grid>
          )}
          {hasPermission(reservationPaidError as ApiError) && (
            <Grid item xs={3}>
              <CardWithStatistics
                iconSettings={{
                  icon: <EventAvailableIcon />,
                  sx: {
                    backgroundColor: 'success.main',
                  },
                  handleClick: () => {
                    const startMonth = moment()
                      .startOf('M')
                      .format('YYYY-MM-DD')
                    const endMonth = moment().endOf('M').format('YYYY-MM-DD')
                    const period = `${startMonth}to${endMonth}`
                    navigate(
                      ROUTES.RESERVATIONS.LIST +
                        `?situation=payment_confirmed&settlement_date_period=${period}`,
                    )
                  },
                }}
                value={currencyMask(reservationPaid?.totalPaidValue || 0, {
                  style: 'currency',
                  currency: 'BRL',
                })}
                title="Reservas pagas (Mês)"
              />
            </Grid>
          )}
          {hasPermission(averageTicketError as ApiError) && (
            <Grid item xs={3}>
              <CardWithStatistics
                iconSettings={{
                  icon: <AttachMoneyIcon />,
                  sx: {
                    backgroundColor: 'success.main',
                  },
                  handleClick: () => {
                    navigate(
                      ROUTES.REPORTS.SALES +
                        `?situation=payment_confirmed&start_date=${START_LAST_30_DAYS}&end_date=${END_LAST_30_DAYS}`,
                    )
                  },
                }}
                value={currencyMask(averageTicket?.average_ticket || 0, {
                  style: 'currency',
                  currency: 'BRL',
                })}
                title="Ticket médio nos últimos 30 dias"
              />
            </Grid>
          )}
          {hasPermission(budgetWaitingApprovedError as ApiError) && (
            <Grid item xs={3}>
              <CardWithStatistics
                iconSettings={{
                  icon: <AttachMoneyIcon />,
                  sx: {
                    backgroundColor: 'warning.light',
                  },
                  handleClick: () => {
                    navigate(ROUTES.BUDGETS.LIST + `?status=awaiting_approval`)
                  },
                }}
                value={budgetWaitingApproved?.totalWaitingApprovalQuantity?.toString()}
                title="Orçamentos Aguardando Aprovações"
              />
            </Grid>
          )}
          {hasPermission(confirmedActivityError as ApiError) && (
            <Grid item xs={3}>
              <CardWithStatistics
                iconSettings={{
                  icon: <Diversity3 />,
                  sx: {
                    backgroundColor: 'success.main',
                  },
                  handleClick: () => {
                    const start = moment().format('YYYY-MM-DD')
                    const end = moment().add(10, 'd').format('YYYY-MM-DD')
                    const period = `${start}to${end}`
                    navigate(
                      ROUTES.ACTIVITIES.LIST +
                        `?status=confirmed&start_travel_date_period=${period}`,
                    )
                  },
                }}
                value={confirmedActivity?.totalConfirmedActivities?.toString()}
                title="Ativ. confirmadas nos próx. 10 dias"
              />
            </Grid>
          )}
          {hasPermission(openedActivityError as ApiError) && (
            <Grid item xs={3}>
              <CardWithStatistics
                iconSettings={{
                  icon: <Diversity3 />,
                  sx: {
                    backgroundColor: 'warning.light',
                  },
                  handleClick: () => {
                    const start = moment().format('YYYY-MM-DD')
                    const end = moment().add(30, 'd').format('YYYY-MM-DD')
                    const period = `${start}to${end}`
                    navigate(
                      ROUTES.ACTIVITIES.LIST +
                        `?status=opened&start_travel_date_period=${period}`,
                    )
                  },
                }}
                value={openedActivity?.totalNotConfirmedActivities?.toString()}
                title="Ativ. em aberto nos próx. 30 dias"
              />
            </Grid>
          )}

          {hasPermission(cancelledActivityError as ApiError) && (
            <Grid item xs={3}>
              <CardWithStatistics
                iconSettings={{
                  icon: <Diversity3 />,
                  sx: {
                    backgroundColor: 'error.light',
                  },
                  handleClick: () => {
                    const start = moment().format('YYYY-MM-DD')
                    const end = moment().add(30, 'd').format('YYYY-MM-DD')
                    const period = `${start}to${end}`
                    navigate(
                      ROUTES.ACTIVITIES.LIST +
                        `?status=cancelled&start_travel_date_period=${period}`,
                    )
                  },
                }}
                value={cancelledActivity?.totalCancelledActivities?.toString()}
                title="Ativ. canceladas nos próx. 30 dias"
              />
            </Grid>
          )}
          {hasPermission(activityChecklistPendingError as ApiError) && (
            <Grid item xs={3}>
              <CardWithStatistics
                iconSettings={{
                  icon: <FactCheckOutlinedIcon />,
                  sx: {
                    backgroundColor: 'warning.light',
                  },
                  handleClick: () => {
                    const start = moment().format('YYYY-MM-DD')
                    const period = `${start}to`
                    navigate(
                      ROUTES.ACTIVITIES.LIST +
                        `?status_checklist=pending&start_travel_date_period=${period}`,
                    )
                  },
                }}
                value={activityChecklistPending?.toString()}
                title="Checklist pendentes"
              />
            </Grid>
          )}
          {checkPermissionSchoolTrip?.approved && (
            <Grid item xs={3}>
              <CardWithStatistics
                iconSettings={{
                  icon: <QueryStats />,
                  sx: {
                    backgroundColor: 'success.main',
                  },
                  handleClick: () => {
                    navigate(ROUTES.REPORTS.SCHOOL_TRIP)
                  },
                }}
                title="Relatório de viagem"
                value="Escola"
              />
            </Grid>
          )}
          {checkPermissionFacultyTrip?.approved && (
            <Grid item xs={3}>
              <CardWithStatistics
                iconSettings={{
                  icon: <QueryStats />,
                  sx: {
                    backgroundColor: 'success.main',
                  },
                  handleClick: () => {
                    navigate(ROUTES.REPORTS.FACULTY_TRIP)
                  },
                }}
                title="Relatório de viagem"
                value="Faculdade"
              />
            </Grid>
          )}
          {checkPermissionActivityControl?.approved && (
            <Grid item xs={3}>
              <CardWithStatistics
                iconSettings={{
                  icon: <CalendarMonth />,
                  sx: {
                    backgroundColor: 'success.main',
                  },
                  handleClick: () => {
                    navigate(ROUTES.ACTIVITIES.LIST_CALENDAR)
                  },
                }}
                title="atividades"
                value="Calendário"
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  )
}
