import {
  useEffect,
  useCallback,
  useState,
  createContext,
  useContext,
} from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import HtmlEditor from 'components/HtmlEditor'
import LoadingFullPage from 'components/LoadingFullPage'
import { QUERY_KEYS } from 'constants/keys'
import { INPUT_MASK } from 'constants/masks'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'
import { formatDate } from 'utils/formatDate'
import { currencyMask } from 'utils/mask'
import { generateId } from 'utils/random'

import { ActivityForm } from './components/ActivityForm'
import { TeachersForm } from './components/Teachers'
import { TransportScheduleForm } from './components/TransportScheduleForm'
import Delete from './delete'
import { schema } from './form-validation'

type OnSave = (activity: ActivityApiRequest) => Promise<void>

type AddProps = {
  activity?: Activity
  onSave?: OnSave
  isEditMode?: boolean
}

export const EDITOR_SIZE = { height: '400px' }

export const ActivityContext = createContext<{
  isEditMode: boolean
  activity?: Activity
  institution_id?: number
}>({
  isEditMode: false,
  activity: undefined,
  institution_id: undefined,
})

export const useActivityContext = () => {
  return useContext(ActivityContext)
}

export default function Add({
  activity,
  onSave,
  isEditMode = false,
}: AddProps) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { openSuccessToast, openErrorToast } = useGlobalState()

  const formProps = useForm<ActivityApiRequest>({
    resolver: yupResolver(schema),
  })

  const { handleSubmit, reset, setValue, watch, getValues } = formProps

  const [formUpdated, setFormUpdated] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Activity | undefined
  >()

  useEffect(() => {
    if (isEditMode && !!activity && !formUpdated) {
      reset({
        commercial_unit_id: activity.commercial_unit_id,
        code: activity.code,
        type: activity.type,
        name: activity.name,
        certificate_name: activity.certificate_name,
        course_name: activity.course_name,
        event: activity.event,
        city: activity.city,
        start_travel_date: activity.start_travel_date,
        end_travel_date: activity.end_travel_date,
        payment_method: activity.payment_method,
        price: currencyMask(activity.price),
        location: activity.location,
        extracurricular_hours: activity.extracurricular_hours,
        viability: activity.viability,
        initial_stock: activity.initial_stock,
        responsible_id: activity.responsible_id,
        installments: activity.installments,
        interest_free_installment: activity.interest_free_installment,
        accept_payment_check: !!activity.accept_payment_check,
        institution_id: activity.institution_id,
        campus_institution_id: activity.campus_institution_id,
        observations: activity.observations,
        departure_at: activity.departure_at,
        return_at: activity.return_at,
        enrollment_limit: activity.enrollment_limit,
        arrival_forecast_last_unit: activity.arrival_forecast_last_unit,
        shipping_company: activity.shipping_company,
        driver_name: activity.driver_name,
        restaurant: activity.restaurant,
        type_shipping_company: activity.type_shipping_company,
        value_shipping_company: currencyMask(activity.value_shipping_company),
        restaurant_package: activity.restaurant_package,
        accompanying_teacher: activity.accompanying_teacher,
        description: activity.description,
        affiliates: activity.affiliates,
        budget_id: activity.budget_id,
        teachers: activity.teachers,
        status: activity.status,
        payment_gateway_id: activity.payment_gateway_id,
        responsibleGuides: activity.responsibleGuides,
        teachersActivities: activity.teachersActivities,
        responsibleGuidesActivities: activity.responsibleGuidesActivities,
        affiliatesActivities: activity.affiliatesActivities,
        accept_subscription: String(activity.accept_subscription),
      })

      setValue('budget_id', activity.budget_id)
      setValue('campus_institution_id', activity.campus_institution_id)
      setValue('payment_gateway_id', activity.payment_gateway_id)
      setValue('type', activity.type)

      setFormUpdated(true)
    }
  }, [isEditMode, activity, formUpdated, reset, setValue])

  const inputProps = useCallback(
    (fieldName: keyof ActivityApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewActivity = useMutation({
    mutationFn: async (activity: ActivityApiRequest) => {
      setIsLoading(true)
      await ApiService.Activities.create(activity)
    },
    onSuccess: (_data, activity: ActivityApiRequest) => {
      queryClient.setQueryData<ActivityApiRequest[]>(
        QUERY_KEYS.ACTIVITIES.LIST,
        (oldActivities) => {
          if (!oldActivities?.length) return

          return oldActivities?.map((currentActivities) =>
            currentActivities.code === activity.code
              ? activity
              : currentActivities,
          )
        },
      )
      setIsLoading(false)
      openSuccessToast({
        message: `A atividade ${activity?.name} foi adicionada com sucesso!`,
      })

      navigate(ROUTES.ACTIVITIES.LIST)
    },
    onError: (error: ApiError, affiliate: ActivityApiRequest) => {
      setIsLoading(false)
      openErrorToast({
        message: error.message || `Erro ao adicionar ${affiliate.name}!`,
      })
    },
  })

  const onSubmit = (formActivity: ActivityApiRequest) => {
    const formattedActivity = formatActivity(formActivity)
    if (isEditMode) {
      setIsLoading(true)
      onSave?.({
        ...formattedActivity,
        budget_id: formActivity.budget_id
          ? formActivity.budget_id
          : (activity?.original_budget_id as number),
      })
      return
    }
    addNewActivity.mutate(formattedActivity)
  }

  const onClickCloneActivity = () => {
    setIsLoading(true)
    const randomActivityId = generateId()
    const formActivity = getValues()
    const formattedActivity = formatActivity({
      ...formActivity,
      budget_id: formActivity.budget_id
        ? formActivity.budget_id
        : (activity?.original_budget_id as number),
      code: randomActivityId,
      shipping_company: '',
      type_shipping_company: '',
      value_shipping_company: '',
      driver_name: '',
      responsibleGuides: [],
      responsibleGuidesActivities: [],
      teachers: [],
      teachersActivities: [],
      accompanying_teacher: [],
    })
    addNewActivity.mutate({
      ...formattedActivity,
      original_activity_id: activity?.id,
    })
  }

  if (isEditMode && formUpdated === false) {
    return <LoadingFullPage />
  }

  if (isLoading) {
    return <LoadingFullPage />
  }

  return (
    <ActivityContext.Provider
      value={{ isEditMode, activity, institution_id: watch('institution_id') }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
          <Container maxWidth={false}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link
                underline="hover"
                color="inherit"
                href={ROUTES.ACTIVITIES.LIST}
              >
                Atividades
              </Link>

              <Typography color="text.primary">
                {isEditMode ? 'Editar' : 'Adicionar'}
              </Typography>
            </Breadcrumbs>

            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                />
                <Typography sx={{ m: 1 }} variant="h4">
                  {isEditMode ? 'Editar' : 'Adicionar'} atividade
                </Typography>
              </Box>

              <Box sx={{ m: 1, display: 'flex' }}>
                {isEditMode && (
                  <Box sx={{ m: 1 }}>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => {
                        setDeleteConfirmation(activity as Activity)
                      }}
                    >
                      Remover
                    </Button>
                  </Box>
                )}

                <Box sx={{ m: 1 }}>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={() => {
                      navigate(ROUTES.ACTIVITIES.LIST)
                    }}
                  >
                    Listar
                  </Button>
                </Box>

                {isEditMode && (
                  <Box sx={{ m: 1 }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={onClickCloneActivity}
                    >
                      Clonar Atividade
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>

            <Box sx={{ mt: 3 }}>
              <FormProvider {...formProps}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6" component="h2">
                          Dados da Atividade
                        </Typography>
                        <Box>
                          <ActivityForm
                            isEditMode={isEditMode}
                            inputProps={inputProps}
                            setValue={setValue}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Box>
                          <Typography variant="h6" component="h2">
                            Roteiro - Informações APP
                          </Typography>

                          <Grid marginTop={2}>
                            <HtmlEditor
                              defaultValue={getValues('observations')}
                              onChange={(observationValue) =>
                                setValue('observations', observationValue)
                              }
                            />
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Box>
                          <Typography variant="h6" component="h2">
                            Horário e Transporte - APP
                          </Typography>

                          <TransportScheduleForm inputProps={inputProps} />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Box>
                          <TeachersForm />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Box>
                          <Typography variant="h6" component="h2">
                            Descrição da Atividade
                          </Typography>

                          <Grid marginTop={2}>
                            <HtmlEditor
                              defaultValue={watch('description')}
                              onChange={(observationValue) =>
                                setValue('description', observationValue)
                              }
                            />
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Box sx={{ my: 2 }}>
                  <Button color="primary" variant="contained" type="submit">
                    Salvar Atividade
                  </Button>
                </Box>
              </FormProvider>
            </Box>
          </Container>
        </Box>

        <Delete
          opened={!!deleteConfirmation}
          closeModal={() => setDeleteConfirmation(undefined)}
          activity={deleteConfirmation as Activity}
        />
      </form>
    </ActivityContext.Provider>
  )
}

export function formatToNumber(number: string) {
  return +number.replaceAll('.', '').replaceAll(',', '.')
}

function formatActivity(activity: ActivityApiRequest): ActivityApiRequest {
  delete activity.accept_payment_check
  const updatedInstallments = activity.installments?.map?.((installment) =>
    Number(installment?.installment) === 1
      ? {
          value_per_installment: formatToNumber(activity?.price?.toString()),
          installment: 1,
        }
      : { ...installment },
  )

  return {
    ...activity,
    campus_institution_id: activity.campus_institution_id,
    end_travel_date: formatDate({
      date: activity.end_travel_date,
      inputFormat: [INPUT_MASK.DATE_LOCALE, INPUT_MASK.DATE_EUA],
      outputFormat: INPUT_MASK.DATE_EUA,
    }),
    enrollment_limit: formatDate({
      date: activity.enrollment_limit,
      inputFormat: [INPUT_MASK.DATE_LOCALE, INPUT_MASK.DATE_EUA],
      outputFormat: INPUT_MASK.DATE_EUA,
    }),
    start_travel_date: formatDate({
      date: activity.start_travel_date,
      inputFormat: [INPUT_MASK.DATE_LOCALE, INPUT_MASK.DATE_EUA],
      outputFormat: INPUT_MASK.DATE_EUA,
    }),
    extracurricular_hours: activity.extracurricular_hours,
    initial_stock: activity.initial_stock,
    institution_id: activity.institution_id,
    payment_gateway_id: activity.payment_gateway_id,
    responsible_id: activity.responsible_id,
    viability: activity.viability,
    installments: updatedInstallments,
    price: formatToNumber(activity.price as unknown as string),
    value_shipping_company: formatToNumber(
      activity.value_shipping_company as unknown as string,
    ),
  }
}
