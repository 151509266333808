import Grid from '@mui/material/Grid'

import { TeacherForm } from './TeacherForm'

export function TeachersForm({
  institutionId,
}: {
  institutionId: number | null
}) {
  return (
    <Grid container rowSpacing={3}>
      <TeacherForm institutionId={institutionId} />
    </Grid>
  )
}
