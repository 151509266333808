import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { TextField } from '@mui/material'
import { Box } from '@mui/material'
import { Breadcrumbs } from '@mui/material'
import { Button } from '@mui/material'
import { Card } from '@mui/material'
import { CardContent } from '@mui/material'
import { Container } from '@mui/material'
import { Dialog } from '@mui/material'
import { DialogTitle } from '@mui/material'
import { Grid } from '@mui/material'
import { Link } from '@mui/material'
import { Typography } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridRenderEditCellParams,
  GridRowEditStopParams,
  GridRowId,
  GridRowModel,
  GridToolbarContainer,
  GridValidRowModel,
  ptBR,
} from '@mui/x-data-grid'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import InputCurrency from 'components/InputCurrency'
import LoadingFullPage from 'components/LoadingFullPage'
import Select, { SelectProps } from 'components/Select'
import { QUERY_KEYS } from 'constants/keys'
import { ROUTES } from 'constants/routes'
import { useGlobalState } from 'contexts/global-state'
import { ACTIVITY_TYPES_OPTION } from 'pages/activities/types'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiService } from 'services/api'
import { convertArrayOfObjectInSingleObjectToGridRowMode } from 'utils/convertArrayOfObjectInSingleObjectToGridRowMode'
import { monetaryToNumber, roundUpperNumber } from 'utils/mask'
import * as yup from 'yup'

import { CustomEditField } from './components/EditField'
import { ResendOrderServiceList } from './components/ResendOrderServiceList'
import { TeachersForm } from './components/Teachers'
import Delete from './delete'
import { addSupplierSchema, schema } from './form-validation'
import {
  important,
  notes,
  paymentMethod,
  programIncludes,
  theAdvertisedPriceDoesNotInclude,
} from './input_default_values'
type OnSave = (
  budget: BudgetsApiRequest & { hasOpenModal?: boolean },
) => Promise<void>

type AddProps = {
  budget?: Budget
  onSave?: OnSave
  isEditMode?: boolean
}
type AddSupplier = yup.InferType<typeof addSupplierSchema>
function generateRandomNumber() {
  let numero = ''
  while (numero.length < 6) {
    numero += Math.floor(Math.random() * 10) // Adiciona um dígito aleatório de 0 a 9
  }
  return parseInt(numero)
}

export const BudgetContext = createContext<{
  isEditMode: boolean
  budget?: Budget
  budget_id?: number
}>({
  isEditMode: false,
  budget: undefined,
  budget_id: undefined,
})

export const useBudgetContext = () => {
  return useContext(BudgetContext)
}

const handleGetStringFieldTypeInstitution: {
  [key: string]: keyof GlobalParametersApiResponse
} = {
  school: 'school_margin_budget',
  college: 'faculty_margin_budget',
}

type ForgetSupplier = {
  id: number
  name: string
}

const filter = createFilterOptions<BudgetItemsForm>()

export function monetaryMask(value: string | number): string {
  let newValue = value

  if (typeof newValue === 'string') {
    newValue = newValue?.replace('.', '').replace(',', '').replace(/\D/g, '')
  }

  if (Number.isNaN(newValue)) return '0,00'

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  const result = new Intl.NumberFormat('pt-BR', options).format(
    roundUpperNumber(Number(newValue)),
  )

  return result
}

export default function Add({ budget, onSave, isEditMode = false }: AddProps) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { openSuccessToast, openErrorToast } = useGlobalState()

  const formProps = useForm<BudgetForm>({
    resolver: yupResolver(schema),
  })

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = formProps

  const dialogMethods = useForm<{
    data: string | ItemsBudget
    name: string
  }>()

  const reactHookFormAddSupplier = useForm<AddSupplier>({
    resolver: yupResolver(addSupplierSchema),
  })

  const selectedCategoryId = reactHookFormAddSupplier.watch('category_id')
  const selectedSupplierId = reactHookFormAddSupplier.watch('supplier_id')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formUpdated, setFormUpdated] = useState<boolean>(false)
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
  const [openSupplierDialog, setOpenSupplierDialog] = useState<boolean>(false)
  const [openIsChangedSupplierDialog, setOpenIsChangedSupplierDialog] =
    useState<boolean>(false)
  const [forgetSupplierItems, setForgetSupplierItems] = useState<
    ForgetSupplier[]
  >([])
  const [openForgetSupplierDialog, setOpenForgetSupplierDialog] =
    useState<boolean>(false)
  const [openReSendOrderServiceDialog, setOpenReSendOrderServiceDialog] =
    useState<boolean>(false)
  const [selectedInstitutionType, setSelectedInstitutionType] = useState('')
  const [budgetItems, setBudgetsItems] = useState<BudgetItemsForm[]>([])
  const [resendOrderServices, setResendOrderServices] = useState<
    Record<string, OrderServiceApiResponse>
  >({})
  const [cancelOrderServices, setCancelOrderServices] = useState<
    Record<string, OrderServiceApiResponse>
  >({})
  const resendOrderServicesArray = useMemo(() => {
    return Object.values(resendOrderServices).filter((item) => item?.id)
  }, [resendOrderServices])
  const cancelOrderServicesArray = useMemo(() => {
    return Object.values(cancelOrderServices).filter((item) => item.id)
  }, [cancelOrderServices])

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    Budget | undefined
  >()

  useEffect(() => {
    if (isEditMode && budget?.budgetItems) {
      setBudgetsItems(
        (budget?.budgetItems || [])?.map((item) => ({
          ...item,
          category_id: item.itemBudget?.category_id,
          old_supplier_id: item.supplier_id,
          default: item?.itemBudget?.default,
          name: item?.itemBudget?.name || '',
        })),
      )
    }
  }, [budget?.budgetItems, isEditMode])

  const { data: commercialUnits } = useQuery<SelectProps['options']>(
    QUERY_KEYS.COMMERCIAL_UNITS.NAMES_AND_IDS,
    async () =>
      ApiService.CommercialUnits.getAllNamesAndIds({
        fields: ['id', 'fantasy_name', 'document'],
        relations: [],
      }),
  )

  const { data: categories } = useQuery<
    CategoriesGetAllNamesIdsFieldsApiResponse[]
  >(QUERY_KEYS.CATEGORIES.NAMES_IDS_FIELDS, () =>
    ApiService.Categories.getAllNamesIdsFields(),
  )

  const { data: suppliers } = useQuery<SelectProps['options']>(
    QUERY_KEYS.SUPPLIERS.NAME_AND_IDS_BY_CATEGORY(selectedCategoryId),
    () =>
      ApiService.Suppliers.getOptionsByCategory({
        category_id: selectedCategoryId,
        supplier_id: selectedSupplierId,
      }),
    {
      enabled: !!selectedCategoryId,
    },
  )
  const filteredSuppliers = useMemo(() => {
    return suppliers
  }, [suppliers])

  const { data: globalParameter } = useQuery<GlobalParametersApiResponse>(
    QUERY_KEYS.GLOBAL_PARAMETERS.LIST,
    async () => {
      const globalsParameters = await ApiService.GlobalParameters.getAll()
      return globalsParameters[0]
    },
  )

  const { data: institutions } = useQuery<GelAllNamesAndIdsOptions[]>(
    QUERY_KEYS.INSTITUTIONS.NAMES_AND_IDS,
    async () =>
      ApiService.Institutions.getAllNamesAndIds({
        fields: ['id', 'fantasy_name', 'document', 'type'],
        relations: [],
      }),
  )

  const { data: paymentGateways } = useQuery<SelectProps['options']>(
    QUERY_KEYS.PAYMENT_GATEWAYS.NAMES_AND_IDS,
    async () => ApiService.PaymentGateways.getAllNamesAndIds(),
  )

  const handleGetDataBudgetItems = useCallback(
    (data: {
      id: number
      name: string
      default?: number
      isNew?: boolean
      category_id?: number | string
      supplier_id?: number | string
      supplier_name?: string
      original_item_id?: number | string
      old_supplier_id?: number | string
    }) => {
      return {
        id: data.id,
        name: data.name,
        unit_value: '',
        quantity: '',
        subtotal: '',
        default: data.default || 0,
        isNew: !!data.isNew,
        category_id: data.category_id,
        supplier_id: data.supplier_id || 45,
        supplier_name: data.supplier_name || 'Sem fornecedor',
        original_item_id: data.original_item_id,
        old_supplier_id: data.supplier_id,
      }
    },
    [],
  )

  const { data: autoCompleteBudgetItems } = useQuery<BudgetItemsForm[]>(
    QUERY_KEYS.ITEMS_BUDGET.LIST,
    async () => {
      const responseItemsBudget = await ApiService.ItemsBudget.getAll()

      return responseItemsBudget
    },
  )

  useEffect(() => {
    if (!autoCompleteBudgetItems?.length) return
    if (!isEditMode && !budgetItems.length) {
      setBudgetsItems(
        autoCompleteBudgetItems
          .filter((itemBudget) => itemBudget.default)
          .map((itemBudget) => {
            return {
              ...handleGetDataBudgetItems({
                ...itemBudget,
                id: itemBudget.id as number,
              }),
            }
          }),
      )
    }
  }, [
    budgetItems.length,
    handleGetDataBudgetItems,
    isEditMode,
    autoCompleteBudgetItems,
  ])

  const institutionId = Number(watch('institution_id') || 0)
  const { data: campi } = useQuery<Campus[]>(
    QUERY_KEYS.CAMPUS.BY_INSTITUTION(institutionId),
    async () => {
      return institutionId
        ? ApiService.Campus.getAllByInstitution(institutionId)
        : []
    },
  )

  const campusOptions = useMemo(
    () =>
      campi?.map((campus) => ({
        label: campus.name,
        value: campus.id,
      })) || [],
    [campi],
  )

  useEffect(() => {
    if (isEditMode && !!budget && !formUpdated) {
      reset({
        name: budget.name,
        number_of_people: budget.number_of_people,
        total_with_margin: budget.total_with_margin,
        total_without_margin: budget.total_without_margin,
        value_per_person: budget.value_per_person,
        cash_value: budget.cash_value,
        installments: budget.installments,
        status: budget.status,
        proposal_code: budget.proposal_code,
        destination: budget.destination,
        group: budget.group,
        date: budget.date,
        departure_time: budget.departure_time,
        expected_arrival_at_the_unit: budget.expected_arrival_at_the_unit,
        pick_up_and_drop_off_location: budget.pick_up_and_drop_off_location,
        program_includes: budget.program_includes || programIncludes,
        payment_method: budget.payment_method || paymentMethod,
        important: budget.important || important,
        the_advertised_price_does_not_include:
          budget.the_advertised_price_does_not_include ||
          theAdvertisedPriceDoesNotInclude,
        notes: budget.notes || notes,
        proposal_price: budget.proposal_price || '0,00',
        teachers: budget.teachers,
        proposalsTeachers: budget.proposalsTeachers,
      })

      setValue('type_institution', budget.type_institution)
      setValue('commercial_unit_id', budget.commercial_unit_id.toString())
      setValue('status', budget.status)
      setValue('institution_id', budget.institution_id.toString())
      setValue('campus_institution_id', budget.campus_institution_id)
      setValue('type_activity', budget.type_activity?.toString())
      setValue('payment_gateway_id', budget.payment_gateway_id.toString())
      setSelectedInstitutionType(budget.type_institution)
      setFormUpdated(true)
    } else if (!isEditMode) {
      reset({
        program_includes: programIncludes,
        payment_method: paymentMethod,
        important: important,
        the_advertised_price_does_not_include: theAdvertisedPriceDoesNotInclude,
        notes: notes,
        proposal_price: '0,00',
      })
    }
  }, [isEditMode, budget, formUpdated, reset, setValue])

  const inputProps = useCallback(
    (fieldName: keyof BudgetsApiRequest) => {
      if (isEditMode) return { InputLabelProps: { shrink: true } }

      return { InputLabelProps: { shrink: !!watch(fieldName) } }
    },
    [isEditMode, watch],
  )

  const addNewBudget = useMutation({
    mutationFn: async ({ budget }: { budget: BudgetsApiRequest }) => {
      await ApiService.Budgets.create({
        ...budget,
        // budgetItems: budgetItems,
      })
    },
    onSuccess: (_data, { budget }: { budget: BudgetsApiRequest }) => {
      openSuccessToast({
        message: `O orçamento ${budget.name} foi adicionada com sucesso!`,
      })

      navigate(ROUTES.BUDGETS.LIST)
    },
    onError: (error: ApiError, { budget }: { budget: BudgetsApiRequest }) => {
      openErrorToast({
        message: error.message || `Erro ao adicionar ${budget.name}!`,
      })
    },
  })

  const addNewItemsBudget = useMutation({
    mutationFn: async (itemBudget: ItemsBudget) => {
      const data = await ApiService.ItemsBudget.create({
        name: itemBudget.name,
        default: itemBudget.default,
        active: !!Number(itemBudget.active),
      })
      return data
    },
    onSuccess: (_data, itemsBudget: ItemsBudget) => {
      queryClient.setQueryData<ItemsBudget[]>(
        QUERY_KEYS.ITEMS_BUDGET.LIST,
        (oldItemsBudget) => {
          if (!oldItemsBudget?.length) return

          return oldItemsBudget.map((currentItemsBudget) => {
            if (currentItemsBudget.id === itemsBudget.id) {
              return itemsBudget
            }

            return currentItemsBudget
          })
        },
      )

      autoCompleteBudgetItems?.push({
        ..._data,
      })
      setBudgetsItems((oldBudgetsItems) => [
        ...oldBudgetsItems,
        {
          ...handleGetDataBudgetItems(_data),
        },
      ])
      setOpenAddDialog(false)
    },
    onError: (error, itemsBudget: ItemsBudget) => {
      const apiError = error as ApiError

      if (apiError?.status === 400) {
        openErrorToast({
          message: `O item de orçamento ${itemsBudget.name} já está cadastrado!`,
        })

        return
      }

      openErrorToast({
        message: `Erro ao adicionar ${itemsBudget.name}!`,
      })
    },
  })

  const AddToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => setOpenAddDialog(true)}
          style={{
            marginLeft: 'auto',
            display: 'block',
            marginTop: '1em',
          }}
        >
          Adicionar
        </Button>
      </GridToolbarContainer>
    )
  }

  const handleCalculateValues = useCallback(
    (updatedBudgetItems?: BudgetItemsForm[]) => {
      const budgetItemsData = updatedBudgetItems || budgetItems
      const typeInstitutionValue =
        watch('type_institution') || selectedInstitutionType
      const numberOfPeopleValue = Number(watch('number_of_people'))
      const globalParameterField =
        handleGetStringFieldTypeInstitution[typeInstitutionValue]

      const totalWithOutMargin = budgetItemsData.reduce(
        (accumulator, nextBudgetItem) => {
          return accumulator + monetaryToNumber(nextBudgetItem.subtotal || '0')
        },
        0,
      )

      if (!globalParameter) {
        return
      }

      const totalMargin =
        totalWithOutMargin *
        (monetaryToNumber(globalParameter[globalParameterField] as string) /
          100)
      const totalWithMargin = totalWithOutMargin + totalMargin

      const valuePerPerson = totalWithMargin / (numberOfPeopleValue || 0)
      const variableTotal =
        monetaryToNumber(globalParameter.variable_budget) + valuePerPerson
      const taxInCash =
        1 + (1 - monetaryToNumber(globalParameter.in_cash_budget))
      const cashValue = variableTotal * taxInCash

      setValue('total_without_margin', monetaryMask(totalWithOutMargin))
      setValue('total_with_margin', monetaryMask(totalWithMargin))
      setValue('value_per_person', monetaryMask(valuePerPerson))
      setValue('cash_value', monetaryMask(cashValue))
      globalParameter?.installments_budget?.forEach((installment, index) => {
        const name = `installments.${index}.value` as keyof BudgetsApiRequest
        const value = variableTotal * monetaryToNumber(installment.value)
        setValue(name, monetaryMask(value))
      })
    },
    [budgetItems, globalParameter, selectedInstitutionType, setValue, watch],
  )

  const handleStartEdit = useCallback((id: GridRowId) => {
    setBudgetsItems((old) =>
      old.map((oldBudgetItem) =>
        oldBudgetItem.id === id
          ? {
              ...oldBudgetItem,
              hasEdit: true,
            }
          : { ...oldBudgetItem },
      ),
    )
  }, [])

  const processRowUpdate = useCallback(
    (newRow: GridRowModel) => {
      const id = newRow.id
      const changes = newRow
      let subtotal = '0'
      if (changes?.quantity > 0) {
        const calculateTotal =
          monetaryToNumber(changes.quantity.toString()) *
          monetaryToNumber(changes.unit_value)
        subtotal = monetaryMask(calculateTotal)
      }

      const updatedRow = {
        ...changes,
        subtotal,
        hasEdit: false,
      } as BudgetItemsForm

      const updatedBudgetItems = budgetItems.map((oldBudgetItem) =>
        oldBudgetItem.id === id ? updatedRow : { ...oldBudgetItem },
      )
      handleCalculateValues(updatedBudgetItems)
      setBudgetsItems(updatedBudgetItems)
      return updatedRow
    },
    [budgetItems, handleCalculateValues],
  )

  const handleSaveClick = useCallback(
    async ({
      id,
      row,
    }: GridRowEditStopParams<{
      budget_id: number
      item_budget_id: number
      supplier_id: number
    }>) => {
      const cacheKey = `${row.budget_id}-${row.item_budget_id}-${row.supplier_id}`
      if (!resendOrderServices[cacheKey]) {
        const responseOrderService =
          await ApiService.OrderServices.getByItemBudgetWithStatusIssuedOrSend({
            budget_id: row.budget_id,
            item_budget_id: row.item_budget_id,
            supplier_id: row.supplier_id,
          })
        setResendOrderServices((oldResendOrderServices) => ({
          ...oldResendOrderServices,
          [cacheKey]: { ...responseOrderService },
        }))
      }

      setBudgetsItems((old) =>
        old.map((oldBudgetItem) =>
          oldBudgetItem.id === id
            ? {
                ...oldBudgetItem,
                hasEdit: false,
              }
            : { ...oldBudgetItem },
        ),
      )
    },
    [resendOrderServices],
  )

  const handleDeleteClick = useCallback(
    (id: GridRowId) => () => {
      const filteredBudgetItems = budgetItems.filter((row) => row.id !== id)
      setBudgetsItems(filteredBudgetItems)
      handleCalculateValues(filteredBudgetItems)
    },
    [budgetItems, handleCalculateValues],
  )

  const handleOpenModalChoiceSupplier = useCallback(
    async (id: GridRowId) => {
      const budgetItem = budgetItems.find((row) => row.id === id)

      if (!budgetItem) return
      if (budgetItem.id) {
        reactHookFormAddSupplier.setValue(
          'item_budget_id',
          budgetItem.id.toString(),
        )
      }

      reactHookFormAddSupplier.setValue(
        'supplier_id',
        budgetItem.supplier_id?.toString() || '',
      )

      if (budgetItem.category_id) {
        reactHookFormAddSupplier.setValue(
          'category_id',
          budgetItem.category_id?.toString() || '',
        )
      }
      // cant set category_id per supplier because now have multiple values
      // if (!budgetItem.category_id && budgetItem.supplier_id) {
      //   const responseSupplier =
      //     await ApiService.Suppliers.getCategoryBySupplier({
      //       supplier_id: budgetItem.supplier_id?.toString() || '',
      //     })
      //   reactHookFormAddSupplier.setValue(
      //     'category_id',
      //     responseSupplier?.category_id?.toString() || '',
      //   )
      // }

      setOpenSupplierDialog(true)
    },
    [budgetItems, reactHookFormAddSupplier],
  )
  // const verifyIfSupplierIsDuplicated = useCallback(
  //   ({ supplier_id }: { supplier_id: string }) => {
  //     const budgetItem = budgetItems.find(
  //       (row) => Number(row.supplier_id) === Number(supplier_id),
  //     )
  //     return budgetItem
  //   },
  //   [budgetItems],
  // )

  const verifyIfSupplierIsChanged = useCallback(
    async ({ newValue, item_id }: { newValue: string; item_id: number }) => {
      if (!budget) {
        return
      }
      const findItem = budget?.budgetItems.find((item) => item.id === item_id)

      if (!findItem) {
        return
      }

      if (!findItem.supplier_id) {
        return
      }

      if (findItem.supplier_id === Number(newValue)) {
        return
      }
      const cacheKey = `${item_id}`

      if (!cancelOrderServices[cacheKey]) {
        const responseOrderService =
          await ApiService.OrderServices.getByItemBudget({
            budget_id: budget.id,
            item_budget_id: Number(findItem.item_budget_id),
            supplier_id: Number(findItem.supplier_id),
          })

        if (!responseOrderService.id) {
          return
        }

        setCancelOrderServices((oldCancelOrderServices) => ({
          ...oldCancelOrderServices,
          [cacheKey]: {
            ...responseOrderService,
          },
        }))
      }
    },
    [budget, cancelOrderServices],
  )

  const onLoading = useCallback((loadingState: boolean) => {
    setIsLoading(loadingState)
  }, [])

  const handleDuplicateBudget = useCallback(async () => {
    if (!budget) {
      return
    }
    setIsLoading(true)
    try {
      const responseDuplicateBudget = await ApiService.Budgets.duplicate(
        budget.id.toString(),
      )
      openSuccessToast({
        message: 'Orçamento duplicado com sucesso',
      })
      navigate(ROUTES.BUDGETS.EDIT(String(responseDuplicateBudget.id)))
    } catch (error) {
      openErrorToast({
        message: `Ocorreu algum erro ao duplicar`,
      })
    } finally {
      setIsLoading(false)
    }
  }, [budget, navigate, openErrorToast, openSuccessToast])

  if (
    isEditMode &&
    !!budget &&
    formUpdated === false &&
    !campusOptions.length
  ) {
    return <LoadingFullPage />
  }

  if (isLoading) {
    return <LoadingFullPage />
  }

  return (
    <BudgetContext.Provider value={{ isEditMode, budget, budget_id: 4 }}>
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link underline="hover" color="inherit" href={ROUTES.BUDGETS.LIST}>
              Orçamentos
            </Link>

            <Typography color="text.primary">
              {isEditMode ? 'Editar' : 'Adicionar'}
            </Typography>
          </Breadcrumbs>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Typography sx={{ m: 1 }} variant="h4">
                {isEditMode ? 'Editar' : 'Adicionar'} orçamento
              </Typography>
            </Box>

            <Box sx={{ m: 1, display: 'flex' }}>
              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setDeleteConfirmation(budget as Budget)
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              )}

              {isEditMode && (
                <Box sx={{ m: 1 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleDuplicateBudget}
                  >
                    Duplicar
                  </Button>
                </Box>
              )}

              <Box sx={{ m: 1 }}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTES.BUDGETS.LIST)
                  }}
                >
                  Listar
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <form
              onSubmit={handleSubmit((budget: Budget) => {
                delete budget.budgetsItems
                let copyBudgetItems = [
                  ...JSON.parse(JSON.stringify(budgetItems)),
                ]
                copyBudgetItems = copyBudgetItems.map((item) => {
                  if (item.isNew && item.original_item_id) {
                    return {
                      ...item,
                      id: item.original_item_id,
                    }
                  }
                  return {
                    ...item,
                  }
                })
                const forgetSupplier: ForgetSupplier[] = []
                for (const item of copyBudgetItems) {
                  if (!item.supplier_id || item.supplier_id === '') {
                    forgetSupplier.push({
                      ...item,
                    })
                  }
                }

                if (forgetSupplier.length) {
                  setForgetSupplierItems(forgetSupplier)
                  setOpenForgetSupplierDialog(true)
                  return
                }

                if (isEditMode) {
                  onSave?.({
                    ...budget,
                    budgetItems: copyBudgetItems,
                    hasOpenModal:
                      !!cancelOrderServicesArray.length ||
                      !!resendOrderServicesArray.length,
                  })
                  if (cancelOrderServicesArray.length) {
                    cancelOrderServicesArray.forEach((item) => {
                      ApiService.OrderServices.cancel({
                        order_service_id: item.id,
                      })
                    })
                    setOpenIsChangedSupplierDialog(true)
                  }
                  if (resendOrderServicesArray.length) {
                    setOpenReSendOrderServiceDialog(true)
                  }
                  return
                }

                addNewBudget.mutate({
                  budget: { ...budget, budgetItems: copyBudgetItems },
                })
                if (resendOrderServicesArray.length) {
                  setOpenReSendOrderServiceDialog(true)
                }
                if (cancelOrderServicesArray.length) {
                  cancelOrderServicesArray.forEach((item) => {
                    ApiService.OrderServices.cancel({
                      order_service_id: item.id,
                    })
                  })
                  setOpenIsChangedSupplierDialog(true)
                }
              })}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={3} xs={12}>
                        <Grid item xs={3}>
                          <Controller
                            name="commercial_unit_id"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  label="Unidade comercial"
                                  id="commercial_unit_id"
                                  onChange={onChange}
                                  value={value}
                                  options={commercialUnits || []}
                                  autoFill={isEditMode}
                                  error={!!errors.commercial_unit_id?.message}
                                  helperText={
                                    errors.commercial_unit_id?.message
                                  }
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            name="institution_id"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  label="Instituição"
                                  id="institution_id"
                                  onChange={(event) => {
                                    handleCalculateValues()
                                    onChange(event)
                                    const findInstitution = institutions?.find(
                                      (institution) =>
                                        Number(institution.value) ===
                                        Number(event.target.value),
                                    )

                                    setSelectedInstitutionType(
                                      findInstitution?.type || '',
                                    )
                                    setValue(
                                      'type_institution',
                                      (findInstitution?.type ||
                                        '') as TypeInstitutions,
                                      { shouldValidate: true },
                                    )
                                  }}
                                  value={value}
                                  options={institutions || []}
                                  autoFill={isEditMode}
                                  error={!!errors.institution_id?.message}
                                  helperText={errors.institution_id?.message}
                                />
                              )
                            }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Controller
                            name="campus_institution_id"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  error={
                                    !!errors?.campus_institution_id?.message
                                  }
                                  helperText={
                                    errors?.campus_institution_id?.message
                                  }
                                  label="Unidade"
                                  id="campus_institution_id"
                                  onChange={onChange}
                                  value={value}
                                  disabled={campi && campi?.length <= 0}
                                  options={campusOptions}
                                  autoFill={isEditMode}
                                />
                              )
                            }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Controller
                            name="type_activity"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  error={!!errors?.type_activity?.message}
                                  helperText={errors?.type_activity?.message}
                                  label="Tipo de Atividade"
                                  id="type_activity"
                                  onChange={onChange}
                                  value={value}
                                  options={ACTIVITY_TYPES_OPTION}
                                  autoFill={isEditMode}
                                />
                              )
                            }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <TextField
                            error={!!errors.name?.message}
                            fullWidth
                            helperText={errors.name?.message}
                            label="Nome da atividade"
                            margin="normal"
                            type="text"
                            variant="outlined"
                            {...inputProps('name')}
                            {...register('name', { required: true })}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            name="payment_gateway_id"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  label="Gateway de Pagamento"
                                  id="payment_gateway_id"
                                  onChange={onChange}
                                  value={value}
                                  options={paymentGateways || []}
                                  autoFill={isEditMode}
                                  error={!!errors.payment_gateway_id?.message}
                                  helperText={
                                    errors.payment_gateway_id?.message
                                  }
                                />
                              )
                            }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Controller
                            name="type_institution"
                            control={control}
                            render={({ field: { onChange } }) => {
                              return (
                                <Select
                                  label="Tipo de Instituição"
                                  id="type_institution"
                                  onChange={(event) => {
                                    setSelectedInstitutionType(
                                      event.target.value as string,
                                    )
                                    handleCalculateValues()
                                    onChange(event)
                                  }}
                                  value={selectedInstitutionType}
                                  autoFill={false}
                                  options={[
                                    {
                                      label: 'Escola',
                                      value: 'school',
                                    },
                                    {
                                      label: 'Faculdade',
                                      value: 'college',
                                    },
                                  ]}
                                  error={!!errors.type_institution?.message}
                                  helperText={errors.type_institution?.message}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            name="number_of_people"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={!!errors.number_of_people?.message}
                                  fullWidth
                                  helperText={errors.number_of_people?.message}
                                  label="Número mínimo de pessoas"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  {...inputProps('number_of_people')}
                                  {...register('number_of_people', {
                                    required: true,
                                  })}
                                  value={value}
                                  onChange={(event) => {
                                    onChange(event)
                                    handleCalculateValues()
                                  }}
                                />
                              )
                            }}
                          />
                        </Grid>
                        {isEditMode && (
                          <Grid item xs={2}>
                            <Controller
                              name="status"
                              control={control}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <Select
                                    label="Status"
                                    id="status"
                                    onChange={onChange}
                                    value={value}
                                    autoFill={isEditMode}
                                    options={[
                                      {
                                        value: 'awaiting_approval',
                                        label: 'Aguardando Aprovação',
                                      },
                                      {
                                        value: 'approved',
                                        label: 'Aprovado',
                                      },
                                      {
                                        value: 'reproved',
                                        label: 'Reprovado',
                                      },
                                    ]}
                                  />
                                )
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={3} xs={12}>
                        {/* {isEditMode && (
                          <Grid item xs={12}>
                            <Controller
                              name="proposal_code"
                              control={control}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <TextField
                                    error={!!errors.proposal_code?.message}
                                    fullWidth
                                    helperText={errors.proposal_code?.message}
                                    label="Código da proposta"
                                    margin="normal"
                                    type="text"
                                    variant="outlined"
                                    {...inputProps('proposal_code')}
                                    {...register('proposal_code', {
                                      required: true,
                                    })}
                                    value={value}
                                    onChange={(event) => {
                                      onChange(event)
                                    }}
                                  />
                                )
                              }}
                            />
                          </Grid>
                        )} */}
                        <Grid item xs={12}>
                          <Controller
                            name="proposal_price"
                            control={control}
                            render={({
                              field: { onChange, value, ...rest },
                            }) => {
                              return (
                                <InputCurrency
                                  error={!!errors?.proposal_price?.message}
                                  helperText={errors?.proposal_price?.message}
                                  fullWidth
                                  label="Preço"
                                  id="proposal_price"
                                  onChange={(event) => {
                                    onChange(event)
                                    handleCalculateValues()
                                  }}
                                  value={value}
                                  {...rest}
                                />
                              )
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Controller
                            name="destination"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={!!errors.destination?.message}
                                  fullWidth
                                  helperText={errors.destination?.message}
                                  label="Destino"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  {...inputProps('destination')}
                                  {...register('destination', {
                                    required: true,
                                  })}
                                  value={value}
                                  onChange={(event) => {
                                    onChange(event)
                                    handleCalculateValues()
                                  }}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            name="group"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={!!errors.group?.message}
                                  fullWidth
                                  helperText={errors.group?.message}
                                  label="Turma"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  {...inputProps('group')}
                                  {...register('group', {
                                    required: true,
                                  })}
                                  value={value}
                                  onChange={(event) => {
                                    onChange(event)
                                    handleCalculateValues()
                                  }}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            name="date"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={!!errors.date?.message}
                                  fullWidth
                                  helperText={errors.date?.message}
                                  label="Data"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  {...inputProps('date')}
                                  {...register('date', {
                                    required: true,
                                  })}
                                  value={value}
                                  onChange={(event) => {
                                    onChange(event)
                                    handleCalculateValues()
                                  }}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            name="departure_time"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={!!errors.departure_time?.message}
                                  fullWidth
                                  helperText={errors.departure_time?.message}
                                  label="Hora saída e retorno"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  {...inputProps('departure_time')}
                                  {...register('departure_time', {
                                    required: true,
                                  })}
                                  value={value}
                                  onChange={(event) => {
                                    onChange(event)
                                    handleCalculateValues()
                                  }}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            name="expected_arrival_at_the_unit"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={
                                    !!errors.expected_arrival_at_the_unit
                                      ?.message
                                  }
                                  fullWidth
                                  helperText={
                                    errors.expected_arrival_at_the_unit?.message
                                  }
                                  label="Previsão de chegada à unidade"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  {...inputProps(
                                    'expected_arrival_at_the_unit',
                                  )}
                                  {...register('expected_arrival_at_the_unit', {
                                    required: true,
                                  })}
                                  value={value}
                                  onChange={(event) => {
                                    onChange(event)
                                    handleCalculateValues()
                                  }}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name="pick_up_and_drop_off_location"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={
                                    !!errors.pick_up_and_drop_off_location
                                      ?.message
                                  }
                                  fullWidth
                                  helperText={
                                    errors.pick_up_and_drop_off_location
                                      ?.message
                                  }
                                  label="Local de embarque e desembarque"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  {...inputProps(
                                    'pick_up_and_drop_off_location',
                                  )}
                                  {...register(
                                    'pick_up_and_drop_off_location',
                                    {
                                      required: true,
                                    },
                                  )}
                                  value={value}
                                  onChange={(event) => {
                                    onChange(event)
                                    handleCalculateValues()
                                  }}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="program_includes"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={!!errors.program_includes?.message}
                                  fullWidth
                                  helperText={errors.program_includes?.message}
                                  label="O programa inclui"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  multiline
                                  minRows={3}
                                  {...inputProps('program_includes')}
                                  {...register('program_includes', {
                                    required: true,
                                  })}
                                  value={value}
                                  onChange={(event) => {
                                    onChange(event)
                                    handleCalculateValues()
                                  }}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="payment_method"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={!!errors.payment_method?.message}
                                  fullWidth
                                  helperText={errors.payment_method?.message}
                                  label="Condições de pagamento"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  multiline
                                  minRows={3}
                                  {...inputProps('payment_method')}
                                  {...register('payment_method', {
                                    required: true,
                                  })}
                                  value={value}
                                  onChange={(event) => {
                                    onChange(event)
                                    handleCalculateValues()
                                  }}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="important"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={!!errors.important?.message}
                                  fullWidth
                                  helperText={errors.important?.message}
                                  label="Importante"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  multiline
                                  minRows={3}
                                  {...inputProps('important')}
                                  {...register('important', {
                                    required: true,
                                  })}
                                  value={value}
                                  onChange={(event) => {
                                    onChange(event)
                                    handleCalculateValues()
                                  }}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="the_advertised_price_does_not_include"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={
                                    !!errors
                                      .the_advertised_price_does_not_include
                                      ?.message
                                  }
                                  fullWidth
                                  helperText={
                                    errors.the_advertised_price_does_not_include
                                      ?.message
                                  }
                                  label="O preço anunciado não inclui"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  multiline
                                  minRows={3}
                                  {...inputProps(
                                    'the_advertised_price_does_not_include',
                                  )}
                                  {...register(
                                    'the_advertised_price_does_not_include',
                                    {
                                      required: true,
                                    },
                                  )}
                                  value={value}
                                  onChange={(event) => {
                                    onChange(event)
                                    handleCalculateValues()
                                  }}
                                />
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="notes"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  error={!!errors.notes?.message}
                                  fullWidth
                                  helperText={errors.notes?.message}
                                  label="Observações"
                                  margin="normal"
                                  type="text"
                                  variant="outlined"
                                  multiline
                                  minRows={3}
                                  {...inputProps('notes')}
                                  {...register('notes', {
                                    required: true,
                                  })}
                                  value={value}
                                  onChange={(event) => {
                                    onChange(event)
                                    handleCalculateValues()
                                  }}
                                />
                              )
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <FormProvider {...formProps}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Box>
                          <TeachersForm institutionId={institutionId} />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </FormProvider>

                <Grid item xs={8}>
                  <Card>
                    <CardContent>
                      <Box>
                        <Typography variant="h5">Itens do Orçamento</Typography>
                      </Box>
                      <Grid container>
                        <DataGrid
                          sx={{
                            border: 0,
                            '& .MuiFormControl-root': {
                              marginTop: 0,
                              marginBottom: 0,
                            },
                            '& .MuiDataGrid-cell--editing': {
                              padding: '0 10px !important',
                            },
                            '& .Mui-error': {
                              outline: 'red 1px solid',
                            },
                            '& .custom-erro-input': {
                              outline: 'none !important',
                            },
                          }}
                          rows={budgetItems as GridValidRowModel[]}
                          rowModesModel={convertArrayOfObjectInSingleObjectToGridRowMode(
                            {
                              data: budgetItems as {
                                id: number
                                hasEdit: boolean
                              }[],
                              field: 'quantity',
                            },
                          )}
                          onRowEditStart={(_, event) => {
                            event.defaultMuiPrevented = true
                          }}
                          onRowClick={(params) => handleStartEdit(params.id)}
                          disableIgnoreModificationsIfProcessingProps
                          experimentalFeatures={{ newEditingApi: true }}
                          columns={[
                            {
                              field: 'name',
                              headerName: 'Item',
                              flex: 1,
                            },
                            {
                              field: 'supplier_name',
                              headerName: 'Fornecedor',
                              flex: 1,
                              valueGetter: (params) => {
                                return params.row?.supplier_name || 'A definir'
                              },
                            },
                            {
                              field: 'quantity',
                              headerName: 'Quantidade',
                              type: 'number',
                              align: 'left',
                              headerAlign: 'left',
                              flex: 1,
                              editable: true,
                              renderCell: (props: GridRenderEditCellParams) => {
                                const fieldName =
                                  `budgetsItems.${props.id}.quantity` as keyof BudgetForm

                                return (
                                  <Controller
                                    name={fieldName}
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={budgetItems
                                      .find((item) => item.id === props.id)
                                      ?.quantity?.toString()}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      return (
                                        <CustomEditField
                                          {...props}
                                          handleCal={(data) => {
                                            handleCalculateValues(data)
                                          }}
                                          setBudget={setBudgetsItems}
                                          budget={budgetItems}
                                          value={value}
                                          onChange={onChange}
                                          error={
                                            !!errors?.budgetsItems?.[
                                              props.id as number
                                            ]?.quantity
                                          }
                                        />
                                      )
                                    }}
                                  />
                                )
                              },
                              renderEditCell: (props) => {
                                const fieldName =
                                  `budgetsItems.${props.id}.quantity` as keyof BudgetForm

                                return (
                                  <Controller
                                    name={fieldName}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      return (
                                        <CustomEditField
                                          {...props}
                                          handleCal={(data) => {
                                            handleCalculateValues(data)
                                          }}
                                          setBudget={setBudgetsItems}
                                          budget={budgetItems}
                                          value={value}
                                          onChange={onChange}
                                          error={
                                            !!errors?.budgetsItems?.[
                                              props.id as number
                                            ]?.quantity
                                          }
                                        />
                                      )
                                    }}
                                  />
                                )
                              },
                              cellClassName: (
                                params: GridCellParams<{ id: number }>,
                              ) => {
                                const hasErro =
                                  !!errors?.budgetsItems?.[params.id as number]
                                    ?.quantity

                                return hasErro ? 'custom-erro-input' : ''
                              },
                            },
                            {
                              field: 'unit_value',
                              headerName: 'Valor Unitário',
                              flex: 1,
                              editable: true,
                              type: 'string',
                              cellClassName: (
                                params: GridCellParams<{ id: number }>,
                              ) => {
                                const hasErro =
                                  !!errors?.budgetsItems?.[params.id as number]
                                    ?.unit_value

                                return hasErro ? 'custom-erro-input' : ''
                              },
                              renderCell: (props) => {
                                const fieldName =
                                  `budgetsItems.${props.id}.unit_value` as keyof BudgetForm

                                return (
                                  <Controller
                                    name={fieldName}
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={budgetItems
                                      .find((item) => item.id === props.id)
                                      ?.unit_value?.toString()}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      return (
                                        <CustomEditField
                                          {...props}
                                          handleCal={(data) => {
                                            handleCalculateValues(data)
                                          }}
                                          setBudget={setBudgetsItems}
                                          budget={budgetItems}
                                          value={value}
                                          onChange={onChange}
                                          error={
                                            !!errors?.budgetsItems?.[
                                              props.id as number
                                            ]?.unit_value
                                          }
                                        />
                                      )
                                    }}
                                  />
                                )
                              },
                              renderEditCell: (props) => {
                                const fieldName =
                                  `budgetsItems.${props.id}.unit_value` as keyof BudgetForm

                                return (
                                  <Controller
                                    name={fieldName}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      return (
                                        <CustomEditField
                                          {...props}
                                          handleCal={(data) => {
                                            handleCalculateValues(data)
                                          }}
                                          setBudget={setBudgetsItems}
                                          budget={budgetItems}
                                          value={value}
                                          onChange={onChange}
                                          error={
                                            !!errors?.budgetsItems?.[
                                              props.id as number
                                            ]?.unit_value
                                          }
                                        />
                                      )
                                    }}
                                  />
                                )
                              },
                            },
                            {
                              field: 'subtotal',
                              headerName: 'Subtotal',
                              flex: 1,
                            },
                            {
                              field: 'actions',
                              type: 'actions',
                              headerName: 'Ações',
                              width: 100,
                              cellClassName: 'actions',
                              renderCell: ({ id, row }) => {
                                return (
                                  <>
                                    <GridActionsCellItem
                                      key={`supplier-${id}`}
                                      icon={<LocalShippingIcon />}
                                      label="Fornecedor"
                                      onClick={() =>
                                        handleOpenModalChoiceSupplier(id)
                                      }
                                      color="inherit"
                                    />
                                    <GridActionsCellItem
                                      key={`cancel-${id}`}
                                      icon={<DeleteIcon />}
                                      label="Delete"
                                      onClick={handleDeleteClick(id)}
                                      color="inherit"
                                      disabled={row.default}
                                    />
                                  </>
                                )
                              },
                            },
                          ]}
                          editMode="row"
                          autoHeight
                          processRowUpdate={processRowUpdate}
                          localeText={
                            ptBR.components.MuiDataGrid.defaultProps.localeText
                          }
                          components={{ Footer: () => <AddToolbar /> }}
                          onRowEditStop={(row) => handleSaveClick(row)}
                        />
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={3} xs={12}>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            error={!!errors.total_without_margin?.message}
                            helperText={errors.total_without_margin?.message}
                            label="Total sem margem"
                            type="text"
                            variant="outlined"
                            {...inputProps('total_without_margin')}
                            {...register('total_without_margin', {
                              required: false,
                            })}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.total_with_margin?.message}
                            fullWidth
                            helperText={errors.total_with_margin?.message}
                            label="Total com margem"
                            type="text"
                            variant="outlined"
                            {...inputProps('total_with_margin')}
                            {...register('total_with_margin', {
                              required: false,
                            })}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.value_per_person?.message}
                            fullWidth
                            helperText={errors.value_per_person?.message}
                            label="Valor por pessoa"
                            type="text"
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                            {...inputProps('value_per_person')}
                            {...register('value_per_person', {
                              required: false,
                            })}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.cash_value?.message}
                            fullWidth
                            helperText={errors.cash_value?.message}
                            label="Valor à vista"
                            type="text"
                            variant="outlined"
                            {...inputProps('cash_value')}
                            {...register('cash_value', {
                              required: false,
                            })}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        {globalParameter?.installments_budget?.map(
                          (installment, index) => {
                            const prefix = `installments.${index}`
                            const name = (prefix +
                              '.value') as keyof BudgetsApiRequest
                            const nameInstallment = (prefix +
                              '.installment') as keyof BudgetsApiRequest
                            const label = `Parcelado em ${installment.installment}X`

                            return (
                              <Grid item xs={6} key={installment.installment}>
                                <TextField
                                  type="text"
                                  variant="outlined"
                                  defaultValue={installment.installment}
                                  {...inputProps(nameInstallment)}
                                  {...register(nameInstallment)}
                                  sx={{ display: { xl: 'none', xs: 'none' } }}
                                />
                                <TextField
                                  error={!!errors?.[name]?.message}
                                  fullWidth
                                  helperText={errors?.[name]?.message}
                                  label={label}
                                  type="text"
                                  variant="outlined"
                                  {...inputProps(name)}
                                  {...register(name, {
                                    required: false,
                                  })}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>
                            )
                          },
                        )}

                        <Grid item xs={12}>
                          <Box>
                            <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                              style={{
                                marginLeft: 'auto',
                                display: 'block',
                                marginTop: '1em',
                              }}
                            >
                              Salvar
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </Box>

      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Adicionar Item</DialogTitle>
        <Box sx={{ py: 1, px: 1 }}>
          <Container maxWidth={false}>
            <Box>
              <form
                onSubmit={dialogMethods?.handleSubmit(
                  ({ data }: { data: string | ItemsBudget }) => {
                    if (typeof data === 'string') {
                      addNewItemsBudget.mutate({
                        ...handleGetDataBudgetItems({
                          default: 0,
                          id: 1,
                          name: data,
                          isNew: true,
                        }),
                        active: 1,
                        hasEdit: true,
                      })
                      return
                    }
                    setBudgetsItems((oldBudgetsItems) => [
                      ...oldBudgetsItems,
                      {
                        ...handleGetDataBudgetItems({
                          ...data,
                          isNew: true,
                          id: generateRandomNumber(),
                          original_item_id: data.id,
                        }),
                        hasEdit: true,
                      },
                    ])
                    setOpenAddDialog(false)
                  },
                )}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Controller
                      name="data"
                      control={dialogMethods.control}
                      rules={{ required: true }}
                      shouldUnregister
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Autocomplete
                            filterSelectedOptions
                            value={value as BudgetItemsForm}
                            onChange={(_, newValue) => {
                              if (newValue?.inputValue) {
                                onChange(newValue.inputValue)
                                return
                              }

                              onChange(newValue)
                            }}
                            noOptionsText="Nada foi encontrado"
                            options={
                              autoCompleteBudgetItems as BudgetItemsForm[]
                            }
                            getOptionLabel={(option) => {
                              if (option.inputValue) {
                                return option.inputValue
                              }

                              return option.name
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params)
                              const { inputValue } = params
                              const isExisting = options.some(
                                (option) => inputValue === option.name,
                              )
                              if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  name: `Adicionar "${inputValue}"`,
                                  id: Math.random() * 999,
                                })
                              }

                              return filtered
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderOption={(props, option) => (
                              <li {...props}>{option.name}</li>
                            )}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={
                                  !!dialogMethods.formState.errors.name?.message
                                }
                                fullWidth
                                helperText={
                                  dialogMethods.formState.errors.name?.message
                                }
                                label="Nome"
                                type="text"
                                variant="outlined"
                              />
                            )}
                          />
                        )
                      }}
                    />
                  </Grid>
                </Grid>
                <Box>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={{
                      marginLeft: 'auto',
                      display: 'block',
                      marginTop: '1em',
                    }}
                  >
                    {isEditMode ? 'Salvar' : 'Adicionar'}
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Box>
      </Dialog>

      <Dialog
        open={openSupplierDialog}
        onClose={() => setOpenSupplierDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Adicionar fornecedor</DialogTitle>
        <Box sx={{ py: 1, px: 1 }}>
          <Container maxWidth={false}>
            <Box>
              <form
                onSubmit={reactHookFormAddSupplier?.handleSubmit(
                  async (data) => {
                    const findSupplier = suppliers?.find(
                      (item) => Number(item.value) === Number(data.supplier_id),
                    )
                    setBudgetsItems((old) =>
                      old.map((oldBudgetItem) =>
                        oldBudgetItem.id === Number(data.item_budget_id)
                          ? {
                              ...oldBudgetItem,
                              supplier_id: data.supplier_id,
                              old_supplier_id: oldBudgetItem.supplier_id,
                              supplier_name: findSupplier?.label,
                              category_id: data.category_id,
                            }
                          : { ...oldBudgetItem },
                      ),
                    )
                    if (data.supplier_id) {
                      await verifyIfSupplierIsChanged({
                        item_id: Number(data.item_budget_id),
                        newValue: data.supplier_id.toString(),
                      })
                    }
                    setOpenSupplierDialog(false)
                  },
                )}
              >
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Controller
                      name="category_id"
                      control={reactHookFormAddSupplier.control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            label="Categorias"
                            id="category_id"
                            onChange={(event) => {
                              reactHookFormAddSupplier.setValue(
                                'supplier_id',
                                '',
                              )

                              onChange(event)
                            }}
                            value={value}
                            options={categories || []}
                            error={
                              !!reactHookFormAddSupplier.formState.errors
                                .category_id?.message
                            }
                            helperText={
                              reactHookFormAddSupplier.formState.errors
                                .category_id?.message
                            }
                          />
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      name="supplier_id"
                      control={reactHookFormAddSupplier.control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            label="Fornecedor"
                            id="supplier_id"
                            onChange={(event) => {
                              const findSupplier = suppliers?.find(
                                (item) => item.value === event.target.value,
                              )
                              reactHookFormAddSupplier.setValue(
                                'supplier_name',
                                findSupplier?.label || '',
                              )
                              // const budgetItem = verifyIfSupplierIsDuplicated({
                              //   supplier_id: event.target.value as string,
                              // })
                              // if (budgetItem) {
                              //   event.target.value = ''
                              //   reactHookFormAddSupplier.setValue(
                              //     'supplier_name',
                              //     '',
                              //   )
                              //   openInfoToast({
                              //     message: `Este fornecedor já esta sendo usando em outro item: ${budgetItem.name}`,
                              //   })
                              // }
                              onChange(event)
                            }}
                            value={value}
                            options={filteredSuppliers || []}
                            disabled={!selectedCategoryId?.length}
                            error={
                              !!reactHookFormAddSupplier.formState.errors
                                .supplier_id?.message
                            }
                            helperText={
                              reactHookFormAddSupplier.formState.errors
                                .supplier_id?.message
                            }
                          />
                        )
                      }}
                    />
                  </Grid>
                </Grid>
                <Box>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={{
                      marginLeft: 'auto',
                      display: 'block',
                      marginTop: '1em',
                    }}
                  >
                    Adicionar
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Box>
      </Dialog>

      <Dialog
        open={openReSendOrderServiceDialog}
        onClose={() => setOpenReSendOrderServiceDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {resendOrderServicesArray?.length === 1
            ? 'Reenviar ordem de serviço'
            : 'Reenviar ordem de serviços'}
        </DialogTitle>
        <Container maxWidth={false}>
          <Box>
            <p>
              {resendOrderServicesArray?.length === 1
                ? `Já existe uma ordem de serviço emitida/enviada, você precisa
            reenviar a ordem com as alterações.`
                : `Já existe algumas ordens de serviço emitida/enviada, você precisa
            reenviar as ordens com as alterações.`}
            </p>
          </Box>
          <ResendOrderServiceList
            items={resendOrderServicesArray}
            onLoading={onLoading}
          />
        </Container>
      </Dialog>

      <Dialog
        open={openIsChangedSupplierDialog}
        onClose={() => setOpenIsChangedSupplierDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Fornecedor alterado</DialogTitle>
        <Container maxWidth={false}>
          <Box>
            {cancelOrderServicesArray.length === 1 ? (
              <p>
                A ordem de serviço criada anteriormente foi cancelada, você deve
                preencher a nova ordem de serviço para o novo fornecedor
              </p>
            ) : (
              <p>
                As ordens de serviço criadas anteriormente foram canceladas,
                você deve preencher as novas ordens de serviço para os novos
                fornecedores
              </p>
            )}
          </Box>
          <Box>
            <Typography>
              {cancelOrderServicesArray.length === 1
                ? 'Ordem cancelada:'
                : 'Ordens canceladas:'}
            </Typography>
            {cancelOrderServicesArray.map((item) => (
              <p key={item.id}>
                {item.category?.name} - Nº {item.number_order_service}{' '}
                {item.name?.length ? `- ${item.name}` : ''} -{' '}
                {item.supplier?.fantasy_name || item.supplier?.name}
              </p>
            ))}
          </Box>
        </Container>
      </Dialog>

      <Dialog
        open={openForgetSupplierDialog}
        onClose={() => setOpenForgetSupplierDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {forgetSupplierItems?.length === 1
            ? 'Item sem fornecedor'
            : 'Itens sem fornecedores'}
        </DialogTitle>
        <Container maxWidth={false}>
          <Box>
            <p>
              {forgetSupplierItems?.length === 1
                ? `O seguinte item estão sem fornecedor selecionado`
                : `Os seguintes itens estão sem fornecedores selecionados`}
            </p>
          </Box>
          <div style={{ padding: '1rem' }}>
            {forgetSupplierItems.map((item, index) => (
              <Box key={item.id}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxHeight: '32px',
                  }}
                >
                  <p>
                    Cod. {item.id} - {item.name}
                  </p>
                </Box>
              </Box>
            ))}
          </div>
        </Container>
      </Dialog>

      <Delete
        opened={!!deleteConfirmation}
        closeModal={() => setDeleteConfirmation(undefined)}
        budget={deleteConfirmation as Budget}
      />
    </BudgetContext.Provider>
  )
}
